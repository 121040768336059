<div class="page-content fade-in-up">


  <div class="row">
    <div class="col-lg-12 col-md-12">

      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title"> Estimate </div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link {{listmenuflag}}" href="#tab-1" data-toggle="tab" (click)="list()"><i
                  class="ti-bar-chart"></i>
                All Estimate List </a>
            </li>
            <li class="nav-item">
              <a class="nav-link {{createmenuflag}}" href="#tab-2" data-toggle="tab" (click)="create('i')"><i
                  class="ti-bar-chart"></i>
                Create Estimate </a>
            </li>
            <li class="nav-item" *ngIf="updatemenuflag == 'active'">
              <a class="nav-link {{updatemenuflag}}" href="#tab-2" data-toggle="tab" (click)="updatemenu()"><i
                  class="ti-bar-chart"></i>
                Update Estimate </a>
            </li>

          </ul>
          <div class="tab-content">
            <div class="show active" id="tab-1" *ngIf="list_flag">
              <br>
              <div class="text-right">
                <button class=" btn btn-outline-primary" (click)="create('i')">Create Estimate</button>
              </div>
              <br>
              <br>
              <table style="border: 2px solid gray;" mat-table [dataSource]="est_list_dataSource"
                class="mat-elevation-z8" multiTemplateDataRows>

                <ng-container matColumnDef="est_id">
                  <th mat-header-cell *matHeaderCellDef> Id </th>
                  <td mat-cell *matCellDef="let element"> {{element['est_id_p']}} </td>
                </ng-container>

                <!-- expand-->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="estimate_list_columns.length">
                    <div class="example-element-detail "
                      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                      <table>
                        <thead>
                          <tr>
                            <th class="table-cell">ID</th>
                            <!-- <th class="table-cell">Work Name </th> -->
                            <th class="table-cell"> Estimate Description </th>
                            <th class="table-cell"> Estimate Date</th>
                            <!-- <th class="table-cell"> Fund To Use </th>
                             -->
                            <!-- <th class="table-cell"> Status </th> -->
                            <th class="table-cell"> Mode </th>
                            <th class="table-cell"> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let t of allEstimateForWork">
                            <td class="table-cell"> {{ t.est_id_p }}</td>
                            <!-- <td class="table-cell"> {{work_list_obj[t.inc_id_f]['pro_name']}} </td> -->
                            <td class="table-cell"> {{t.est_desc}}</td>
                            <td class="table-cell"> {{t.est_date}}</td>
                            <!-- <td class="table-cell"> {{t.fund_use}}</td> -->
                            <!-- <td class="table-cell"> {{t.status}}</td> -->
                            <td class="table-cell">{{t.est_type}}</td>
                            <td class="table-cell">

                              <!-- <button class=" btn btn-outline-primary" (click)="Appr(t)"
                                *ngIf="t['status'] == 'GENERATED' && apprflag == true">
                                Add Approval
                              </button> -->

                              <button class=" btn btn-outline-primary" (click)="viewStatus(t)"
                                *ngIf="t['status'] != 'GENERATED'"> View
                                Status
                              </button>

                              <button class=" btn btn-outline-danger" (click)="deleteest(t)"
                                *ngIf="t['status'] == 'GENERATED'"> Delete
                              </button>

                              <button class="btn btn-outline-primary" (click)="open_update(t)"
                                *ngIf="t['status'] == 'GENERATED'"> Update
                              </button>

                              <button class="btn btn-outline-primary" *ngIf=" t.est_type == 'GENERAL'"
                                (click)="view_dtl(t)">View Details
                              </button>

                              <!-- See Workflow Details -->
                              <!-- <button class="btn btn-success" (click)="seeworkflow(t)">
                                                             {{ mainService.allLableShowObj[
                                                                mainService.language_cd + "EMB-EMB18" ] }}
                                                              </button> -->


                              <button class="btn btn-outline-warning" *ngIf="t.est_type != 'GENERAL'"
                                (click)="download_inputs(t)">Download Inputs
                              </button>

                              <button class="btn btn-outline-secondary" *ngIf="t.est_type != 'GENERAL'"
                                (click)="downlod_meas(t)">Download Details Of
                                Measurement
                              </button>

                              <button class="btn btn-outline-success" *ngIf="t.est_type != 'GENERAL'"
                                (click)="download_ana(t)">Download Analysis Of
                                Rate
                              </button>

                              <button class="btn btn-outline-warning" *ngIf="t.est_type != 'GENERAL'"
                                (click)="download_boq(t)">Download Boq
                              </button>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </td>
                </ng-container>
                <!-- expand-->


                <ng-container matColumnDef="work_name">
                  <th mat-header-cell *matHeaderCellDef> Work Name </th>
                  <td mat-cell *matCellDef="let element">
                    {{work_list_obj[element.inc_id_f]['pro_name']}} </td>
                </ng-container>

                <ng-container matColumnDef="est_desc">
                  <th mat-header-cell *matHeaderCellDef> Estimate Description </th>
                  <td mat-cell *matCellDef="let element"> {{element.est_desc}} </td>
                </ng-container>


                <ng-container matColumnDef="fund_to_use">
                  <th mat-header-cell *matHeaderCellDef> Fund To Use </th>
                  <td mat-cell *matCellDef="let element"> {{element.fund_use}} </td>
                </ng-container>
                <!--
                <ng-container matColumnDef="est_date">
                  <th mat-header-cell *matHeaderCellDef> Estimate Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.est_date}} </td>
                </ng-container> -->

                <!-- <ng-container matColumnDef="cal_est">
                  <th mat-header-cell *matHeaderCellDef> Calculation Estimate (Rs.)</th>
                  <td mat-cell *matCellDef="let element"> {{element.finalAmt}} </td>
                </ng-container> -->

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Status </th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <ng-container matColumnDef="mode">
                  <th mat-header-cell *matHeaderCellDef> Mode </th>
                  <td mat-cell *matCellDef="let element">
                    <!-- {{element.est_type}} -->
                    <span *ngIf="element.isBridge"> BRIDGE</span>
                    <span *ngIf="element.isRoad"> &nbsp;ROAD</span>
                    <span *ngIf="element.isGen">&nbsp;GENERAL</span>

                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <!-- (click)="viewEst(element)" -->


                    <button class=" btn btn-outline-primary" (click)="Appr(element); $event.stopPropagation()"
                      *ngIf="element['status'] == 'GENERATED' && apprflag == true ">
                      Add Approval
                    </button>

                    <button class=" btn btn-outline-primary" (click)="viewStatus(element); $event.stopPropagation()"
                      *ngIf="element['status'] != 'GENERATED' "> View Status
                    </button>

                    <button class=" btn btn-outline-danger" (click)="deleteest(element); $event.stopPropagation()"
                      *ngIf="element['status'] == 'GENERATED' && element['estimates'].length == 1 "> Delete </button>

                    <button class="btn btn-outline-primary" (click)="open_update(element); $event.stopPropagation()"
                      *ngIf="element['status'] == 'GENERATED' && element['estimates'].length == 1 "> Update </button>

                    <button class="btn btn-outline-primary"
                      *ngIf="element['estimates'].length == 1 && element.est_type == 'GENERAL' "
                      (click)="view_dtl(element); $event.stopPropagation()">View Details
                    </button>

                    <!-- See Workflow Details -->
                    <!-- *ngIf="element['estimates'].length == 1" -->

                    <button class="btn btn-success" (click)="seeworkflow(element) ; $event.stopPropagation()">
                      {{ mainService.allLableShowObj[
                      mainService.language_cd + "EMB-EMB18" ] }}
                    </button>


                    <button *ngIf="element['estimates'].length != 1"
                      (click)="toggleRowExpansion(element,'d'); $event.stopPropagation()"
                      style="border: none; background: transparent;">
                      <span *ngIf="(expandedElement == element );  then iAmDone; else iAmNotDone">
                      </span>
                      <ng-template #iAmDone>
                        <button class="btn btn-success">
                          Hide
                        </button>
                      </ng-template>
                      <ng-template #iAmNotDone>
                        <button class="btn btn-primary" (click)="viewEst(element)">
                          View More
                        </button>
                      </ng-template>
                    </button>


                    <!-- *ngIf="" -->
                    <button class="btn btn-outline-warning" *ngIf="element['estimates'].length == 1 && !element.isGen"
                      (click)="download_inputs(element); $event.stopPropagation()">Download Inputs
                    </button>
                    <button class="btn btn-outline-secondary"
                      *ngIf="element['estimates'].length == 1 && !element.isGen "
                      (click)="downlod_meas(element); $event.stopPropagation()">Download Details Of
                      Measurement
                    </button>
                    <button class="btn btn-outline-success" *ngIf="element['estimates'].length == 1 && !element.isGen "
                      (click)="download_ana(element); $event.stopPropagation()">Download Analysis Of Rate
                    </button>
                    <button class="btn btn-outline-warning" *ngIf="element['estimates'].length == 1 && !element.isGen "
                      (click)="download_boq(element); $event.stopPropagation()">Download Boq
                    </button>

                    <!-- Create  buttons  -->
                    <button type="button" class="btn btn-outline-primary"
                      *ngIf="!element.isGen && element['status'] == 'GENERATED'"
                      (click)="create(element); $event.stopPropagation()">Create General
                      Estimate</button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-outline-primary"
                      *ngIf="!element.isBridge && element['status'] == 'GENERATED'"
                      (click)="create(element); $event.stopPropagation()">Create Bridge
                      Estimate</button>
                    <button type="button" class="btn btn-outline-primary ml-2"
                      *ngIf="!element.isRoad && element['status'] == 'GENERATED'"
                      (click)="create(element); $event.stopPropagation()">Create Road
                      Estimate</button>
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="estimate_list_columns"></tr>



                <tr mat-row *matRowDef="let row; columns: estimate_list_columns;"
                  [class.example-expanded-row]="expandedElement === row" class="hover-row"
                  (click)="toggleRowExpansion(row,'d')"></tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
              </table>
              <!-- <mat-paginator   [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->


            </div>
            <div class="" id="tab-2" *ngIf="create_flag || update_flag">
              <button (click)="toDown()" class="to-bottom" appScrollToBottom>
                <i class="fa fa-angle-double-down"></i>
              </button>

              <br>
              <mat-horizontal-stepper [linear]="isLinear" #stepper>
                <mat-step [stepControl]="first" label="Basic Details">
                  <br>
                  <div class="text-right">
                    <button class="btn btn-outline-primary" (click)="list()">Estimate List</button>
                  </div>
                  <br>
                  <br>
                  <div class="row" *ngIf="!(update_flag)">
                    <div class="col-2"></div>
                    <div class="col-3 text-right">
                      <h6> Select Work <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left">
                      <h6>
                        <ng-select [items]="work_list" bindLabel="pro_name" bindValue="inc_id_p" [multiple]="false"
                          placeholder="Select work" [(ngModel)]="Obj['work_name']" [selectableGroup]="true"
                          [selectableGroupAsModel]="false" (change)='workChanged()' [closeOnSelect]="true">
                        </ng-select>
                      </h6>

                    </div>
                    <div class="col-2"></div>
                  </div>
                  <div class="row" *ngIf="update_flag">
                    <div class="col-2"></div>
                    <div class="col-3 text-right">
                      <h6> Selected Work <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left">
                      <h6>{{this.Obj['work_name']}}</h6>

                    </div>
                    <div class="col-2"></div>
                  </div>
                  <br>
                  <br>
                  <div class="row">

                    <!-- <div class="col-3 text-left">
                                          <h6 style="margin-left: 2%;">Select Project :</h6>
                                      </div>
                                      <div class="col-3">
                                          <ng-select [items]="AllProjectList" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select ........" [(ngModel)]="Obj['project_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="project_cd">
                                          </ng-select>
                                      </div> -->
                    <div class="col-2 text-left">
                      <h6 style="margin-left: 2%;">
                        <!-- Select Node : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK17']}}<span
                          class="text-danger">*</span>

                      </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3">
                      <button class="btn btn-outline-primary" (click)="selectProjectPopUp()">Select
                        Project</button>
                    </div>

                    <br>
                    <div class="col-2 text-left">
                      <h6 style="margin-left: 2%;">
                        <!-- Select Node : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK73']}}<span
                          class="text-danger">*</span>


                      </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left">
                      {{Obj['path_desc']}}
                    </div>


                  </div>
                  <br>
                  <div class="row">
                    <div class="col-2 text-left">
                      <h6 style="margin-left: 2%;">
                        Select Budget<span class="text-danger">*</span>
                      </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>

                    <div class="col-3 text-left">
                      <button class="btn btn-outline-primary" (click)="selectbudgetbtn()">Select
                        Budget</button>
                    </div>

                    <div class="col-2 text-left">
                      <h6 style="margin-left: 2%;">Budget Path<span class="text-danger">*</span>
                      </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3">
                      {{budpath}}
                    </div>
                  </div>
                  <br>
                  <div class="row">

                    <div class="col-2 text-left">
                      <h6 style="margin-left: 2%;">
                        <!-- Budget Head Code: -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK19']}}<span
                          class="text-danger">*</span>

                      </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3">

                      <input type="text" class="form-control" placeholder=" Budget Head Code"
                        [(ngModel)]="Obj['budget_cd']" disabled>
                    </div>
                    <div class="col-2 text-left">
                      <h6 style="margin-left: 2%;">
                        <!-- Budget Amount : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK20']}}<span
                          class="text-danger">*</span>

                      </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3">
                      <input type="text" class="form-control" placeholder=" Budget Amount"
                        [(ngModel)]="Obj['budget_amt']" disabled>
                    </div>
                  </div>
                  <br>
                  <div class="row">

                    <div class="col-2">
                      <h6> Estimate ID </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left">
                      <input type="text" class="form-control" [(ngModel)]="Obj['est_id_p']"
                        placeholder="System generated" disabled>
                    </div>
                    <div class="col-2">
                      <h6> Estimate Description<span class="text-danger">*</span> </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left">
                      <input type="text" class="form-control" [(ngModel)]="Obj['est_desc']">
                    </div>

                  </div>
                  <br>
                  <div class="row">
                    <div class="col-2">
                      <h6>Estimate Date<span class="text-danger">*</span> </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left ">
                      <input type="date" class="form-control" [(ngModel)]="Obj['est_date']"
                        placeholder="System generated">
                    </div>
                    <div class="col-2">
                      <h6> Remark </h6>
                    </div>
                    <div class="col-1">
                      <h6>:</h6>
                    </div>
                    <div class="col-3 text-left ">
                      <textarea name="" [(ngModel)]="Obj['est_remark']" cols="50" rows="3"
                        class="form-control"></textarea>
                    </div>

                  </div>
                  <br>
                  <div class="row">
                    <div class="col-2">
                      <h6> Funds to Use<span class="text-danger">*</span> </h6>
                    </div>
                    <div class="col-1">
                      <h6> : </h6>
                    </div>
                    <div class="col-3 text-left">
                      <ng-select [multiple]="false" placeholder="Select Unit"
                        [items]="mainService.codeValueTechObj['EMB075']" bindLabel="value" bindValue="code"
                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                        name="hierarchy" [(ngModel)]="Obj['fund_use']">
                      </ng-select>
                    </div>
                  </div>
                  <br><br>

                  <fieldset>
                    <legend>
                      <h6> Upload Drawing Files </h6>
                    </legend>
                    <div class="row">
                      <div class="col-12 text-right">
                        <button class=" btn btn-outline-primary" (click)="addRowFiles()"><i class="fa fa-plus"
                            aria-hidden="true"></i></button>
                      </div>
                    </div>
                    <br>
                    <br>
                    <div *ngFor="let ln of uploadFile; let i = index;">
                      <div class="row">
                        <div class="text-center col-1">
                          <h6>{{i+1}}</h6>
                        </div>
                        &nbsp;&nbsp;
                        <div class="text-center col-2">
                          <h6 style="margin-top: 2px;">File Type : </h6>
                        </div>
                        &nbsp;&nbsp;
                        <div class="col-2">
                          <ng-select [multiple]="false" placeholder="Select Unit"
                            [items]="mainService.codeValueTechObj['EMB062']" bindLabel="value" bindValue="code"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="hierarchy" [(ngModel)]="uploadFile[i]['doc_type']">
                          </ng-select>

                        </div>
                        &nbsp;&nbsp;
                        <div class="col-2">
                          <h6 style="margin-top: 2px;">Upload File :</h6>
                        </div>
                        &nbsp;&nbsp;
                        <div class="col-2">
                          <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="fileupload"
                            (change)="onFileUpload($event,file.files)" [(ngModel)]="uploadFile[i]['doc_name']">
                        </div>
                        &nbsp;&nbsp;
                        <div class="col-1">

                          <button *ngIf="uploadFile[i]['upflag']" class=" btn btn-outline-primary"
                            (click)="viewdocs(uploadFile[i])"> View</button>
                          <button *ngIf="!(uploadFile[i]['upflag'])" class="btn btn-outline-primary"
                            (click)="docUpload(uploadFile[i]['doc_type'],i)"> Upload
                          </button>
                        </div>

                        &nbsp;&nbsp;
                        <div class="col-1">
                          <button class=" btn btn-outline-danger" (click)="deleteRowFiles(i)"><i class="fa fa-trash"
                              aria-hidden="true"></i></button>
                        </div>
                      </div>
                      <br>
                    </div>
                    <br>

                  </fieldset>
                  <br>
                  <div class="row">
                    <div class="col-12 text-center" *ngIf="!update_flag">
                      <!-- <button class="btn btn-outline-primary"
                                                                  (click)="firstnext(stepper)">next</button> -->
                      <button type="button" class="btn btn-outline-primary" *ngIf="!isGen"
                        (click)="generalEst(stepper)">General
                        Estimate</button>&nbsp;&nbsp;
                      <button type="button" class="btn btn-outline-primary" *ngIf="!isBridge"
                        (click)="bridgeEst(stepper)">Bridge
                        Estimate</button>
                      <button type="button" class="btn btn-outline-primary ml-2" *ngIf="!isRoad"
                        (click)="roadEst(stepper)">Road
                        Estimate</button>
                    </div>
                    <div class="col-12 text-center" *ngIf="update_flag">

                      <button type="button" class="btn btn-outline-primary" (click)="generalEst(stepper)"
                        *ngIf="generalUpdate">Update General Estimate</button>&nbsp;&nbsp;
                      <button type="button" class="btn btn-outline-primary" (click)="bridgeEst(stepper)"
                        *ngIf="bridgeUpdate">Update Bridge Estimate</button>
                      <button type="button" class="btn btn-outline-primary" (click)="roadEst(stepper)"
                        *ngIf="roadUpdate">Update Road Estimate</button>
                    </div>
                  </div>

                </mat-step>
                <mat-step [stepControl]="second" label="{{_step_second_label}}">
                  <div [hidden]="_general_est_flag">
                    <br>
                    <div class="row">
                      <div class="col-3"></div>
                      <br>
                      <div class="col-3">Total Amount for Scheduled Items (Rs) : </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['scheduledItemsAmount']"
                          disabled>

                      </div>
                      <div class="col-3"></div>
                    </div>
                    <br><br>
                    <fieldset>
                      <legend>
                        <h6> Add Items for Estimation </h6>
                      </legend>

                      <!-- <div class="row">

                                          <div class="col-12 text-right">
                                               <button class=" btn btn-outline-warning"(click)="addRowSORItems()"><i
                                                      class="fa fa-plus" aria-hidden="true"></i></button> -->
                      <!-- </div>
                                      </div>
                                      <br>  -->

                      <div class="row">
                        <div class="col-11">
                          <mat-form-field>
                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                          </mat-form-field>
                        </div>
                        <div class="col-1">
                          <button class=" btn btn-outline-primary" (click)="addRowSORItems()"><i class="fa fa-plus"
                              aria-hidden="true"></i></button>
                        </div>
                      </div>

                      <div class="example-container">
                        <table class="table table-striped">
                          <thead>
                            <tr style="background-color: #AED6F1;">
                              <th scope="col" style="width: 80px;">Sr. No.</th>
                              <th scope="col" style="width: 150px;">Costing Rate Standard
                              </th>
                              <th scope="col" style="width: 78px">Chapter</th>
                              <th scope="col" style="width: 80px">SI No. / Code No.</th>
                              <th scope="col" style="width: 80px">Group</th>
                              <th scope="col" style="width: 700px" class="text-center">
                                Item Description</th>
                              <th scope="col" style="width: 80px"> Quantity</th>
                              <th scope="col" style="width: 80px">Unit</th>
                              <th scope="col" style="width: 80px">Rate</th>
                              <th scope="col" style="width: 80px">ADD / DED</th>
                              <th scope="col" style="width: 80px">Excise Duty</th>
                              <th scope="col" style="width: 80px">Vat</th>
                              <th scope="col" style="width: 80px">Frieght Charge</th>
                              <th scope="col" style="width: 80px">Atodl</th>
                              <th scope="col" style="width: 80px">Amount (Rs)</th>
                              <th scope="col" style="width: 80px">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let element of addRowSorItems; let i=index">
                              <th scope="row">
                                <p style="width: 80px">{{i+1}}</p>
                              </th>
                              <td *ngIf="element['sub_group_desc'] == 'NA' ">
                                <ng-select [items]="cost_rate_std" bindLabel="cost_rate_desc" bindValue="item_desc"
                                  [multiple]="false" placeholder="Select Costing Rate Standard"
                                  [(ngModel)]="addRowSorItems[i]['cost_rate_std']" [selectableGroup]="true"
                                  [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                  (change)="chap(addRowSorItems[i]['cost_rate_std'],i)"
                                  style="width: 150px"></ng-select>
                              </td>
                              <td *ngIf="element.sub_group_desc != 'NA'" style="width: 150px">
                                <p>{{addRowSorItems[i]['cost_rate_desc']}}</p>
                              </td>
                              <td *ngIf=" element['sub_group_desc'] == 'NA'">
                                <ng-select [items]="chapter" bindLabel="chapter_desc" bindValue="chapter_desc"
                                  [multiple]="false" placeholder="Select Chapter"
                                  [(ngModel)]="addRowSorItems[i]['chapter']" [selectableGroup]="true"
                                  [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                  (change)="si_code_filter(addRowSorItems[i]['cost_rate_std'],addRowSorItems[i]['chapter'],i)"
                                  style="width: 78px">
                                </ng-select>
                              </td>
                              <td *ngIf="element.sub_group_desc != 'NA' " style="width: 150px">
                                <p>{{addRowSorItems[i]['chapter']}}</p>
                              </td>
                              <td *ngIf="  element['sub_group_desc'] == 'NA'">
                                <ng-select [items]="si_code" bindLabel="sed_item_no" bindValue="sed_item_no"
                                  [multiple]="false" placeholder="Select SI No. / Code No."
                                  [(ngModel)]="addRowSorItems[i]['si_code']" [selectableGroup]="true"
                                  [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                  (change)="group_filter(addRowSorItems[i]['cost_rate_std'],addRowSorItems[i]['chapter'],addRowSorItems[i]['si_code'],i)"
                                  style=" min-width: 150px">
                                </ng-select>
                              </td>
                              <td *ngIf="element.sub_group_desc != 'NA'" style="width: 150px">
                                <p>{{addRowSorItems[i]['si_code']}}</p>
                              </td>
                              <td *ngIf="  element['sub_group_desc'] == 'NA'">
                                <ng-select [items]="group" bindLabel="group_desc" bindValue="group_desc"
                                  [multiple]="false" placeholder="Select Group"
                                  [(ngModel)]="addRowSorItems[i]['group_desc']" [selectableGroup]="true"
                                  [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                  (change)="sub_group(addRowSorItems[i]['cost_rate_std'],addRowSorItems[i]['chapter'],addRowSorItems[i]['si_code'],addRowSorItems[i]['group_desc'],i,element)"
                                  style=" min-width: 100px">
                                </ng-select>
                              </td>
                              <td *ngIf="element.sub_group_desc != 'NA'" style="width: 150px">
                                <p>{{addRowSorItems[i]['group_desc']}}</p>
                              </td>
                              <td>
                                <p style=" min-width: 700px">{{element['item_desc']}}
                                </p>
                              </td>
                              <td> <input type="number" class="form-control" placeholder="Amount"
                                  [(ngModel)]="addRowSorItems[i]['item_qty']" (change)="colculate(i)"
                                  style=" min-width: 80px">
                              </td>
                              <td style=" min-width: 80px">
                                {{addRowSorItems[i]['qty_unit']}}
                              </td>
                              <td>
                                <input type="number" class="form-control min-width: 150px" placeholder="Amount"
                                  [(ngModel)]="addRowSorItems[i]['costing_rate']" (change)="colculate(i)"
                                  style=" min-width: 80px">
                              </td>
                              <td style="min-width: 80px">
                                <ng-select [items]="add_ded" bindLabel="code" bindValue="value" [multiple]="false"
                                  placeholder="Select Group" [(ngModel)]="addRowSorItems[i]['add_ded']"
                                  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                  (change)="colculate(i)">
                                </ng-select>
                              </td>
                              <td><input type="number" class="form-control" placeholder="Amount"
                                  [(ngModel)]="addRowSorItems[i]['excise_duity']" (change)="colculate(i)"
                                  style=" min-width: 80px">
                              </td>
                              <td> <input type="number" class="form-control" placeholder="Amount"
                                  [(ngModel)]="addRowSorItems[i]['vat']" (change)="colculate(i)"
                                  style=" min-width: 120px">
                              </td>
                              <td><input type="number" class="form-control" placeholder="Amount"
                                  [(ngModel)]="addRowSorItems[i]['frieght_char']" (change)="colculate(i)"
                                  style=" min-width: 120px">
                              </td>
                              <td><input type="number" class="form-control" placeholder="Amount"
                                  [(ngModel)]="addRowSorItems[i]['atodl']" (change)="colculate(i)"
                                  style=" min-width: 80px">
                              </td>
                              <td style=" min-width: 120px">
                                {{addRowSorItems[i]['amount']}}</td>
                              <td *ngIf="element.deleteflag"> <button class="btn btn-outline-danger"
                                  (click)="deleteSubGroupItems(i)"><i class="fa fa-trash"
                                    aria-hidden="true"></i></button>
                              </td>
                              <td *ngIf="element.deleteflag==false">

                              </td>
                            </tr>


                          </tbody>
                        </table>
                      </div>
                      <!-- <div class="row">
                                          <div class="col-12 text-left">
                                              <button class="btn btn-primary" (click)="addRowSOR()">Add SOR Items
                                              </button>
                                              <hr id="hr-in-fieldset">
                                          </div>
                                      </div> -->
                      <!-- <div class="row">
                                          <div class="col-3"></div>
                                          <div class="col-3 text-right">
                                              <h6> Total Amount for SOR (Rs): </h6>
                                          </div>
                                          <div class="col-3">
                                              <input type="text" class="form-control" placeholder="670.00"
                                                  [(ngModel)]="estimateCal['sor_tot']" disabled>
                                          </div>

                                          <div class="col-3"><button class="btn btn-primary" (click)="viewSORDoc()">
                                                  View List </button></div>
                                      </div>
                                      <br> -->


                      <!-- <div class="row">
                                          <div class="col-12 text-left">
                                              <button class="btn btn-primary" (click)="addRowDSR()">Add DSR Items
                                              </button>
                                              <hr id="hr-in-fieldset">
                                          </div>
                                      </div>

                                      <div class="row">
                                          <div class="col-3"></div>
                                          <div class="col-3 text-right">
                                              <h6> Total Amount for DSR (Rs): </h6>
                                          </div>
                                          <div class="col-3">
                                              <input type="text" class="form-control" placeholder="2651.00"
                                                  [(ngModel)]="estimateCal['dsr_tot']" disabled>
                                          </div>
                                          <div class="col-3">
                                              <button class="btn btn-primary" (click)="viewDSRDoc()">
                                                  View List </button>
                                          </div>
                                      </div>
                                      <br> -->


                      <!-- <div class="row">
                                          <div class="col-12 text-left">
                                              <button class="btn btn-primary" (click)="addRowMoRTH()">Add MoRTH Items
                                              </button>
                                              <hr id="hr-in-fieldset">
                                          </div>
                                      </div> -->

                      <!-- <div class="row">
                                          <div class="col-3"></div>
                                          <div class="col-3 text-right">
                                              <h6>Total Amount for MoRTH (Rs): </h6>
                                          </div>
                                          <div class="col-3">
                                              <input type="text" class="form-control" placeholder="161457.00"
                                                  [(ngModel)]="estimateCal['dsr_tot']" disabled>
                                          </div>

                                          <div class="col-3"><button class="btn btn-primary" (click)="viewMoRTHDoc()">
                                                  View List </button></div>
                                      </div> -->
                      <br><br>
                      <div class="row">
                        <div class="col-12 text-center">
                          <button mat-button matStepperPrevious
                            class="btn btn-outline-primary text-center">Back</button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <button mat-button class="btn btn-outline-primary text-center"
                            (click)="secondnext(stepper)">Next</button>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div [hidden]="_bridge_est_flag">

                    <fieldset>
                      <br>
                      <h6 class="text-danger">Please fill all the required field which is mark as asterisk ( * )</h6>
                      <br>
                      <div class="row">
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                        <div class="col-2 text-center">
                          <h6 id="h" style="color: brown; min-height: 20px;">
                            Bridge Estimate
                          </h6>
                        </div>
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-3 text-left">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Type of Bridge<span class="text-danger">*</span>
                          </h6>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-2">
                          <ng-select [items]="_type_of_bridge" bindLabel="code" bindValue="value" [multiple]="false"
                            placeholder=" Type of Bridge" [(ngModel)]="_bridge_obj['bridge_location']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            (change)="bridgeType()" name="project_cd">
                          </ng-select>
                        </div>

                        <div class="col-3 text-left" *ngIf="_bridge_flag">
                          <h6 style="margin: 2% 0% 0% 2%">
                            {{_bridge_type_hidding}}<span class="text-danger">*</span>
                          </h6>
                        </div>

                        <div class="col-1" *ngIf="_bridge_flag">:</div>
                        <div class="col-2 text-left" *ngIf="_bridge_flag">
                          <ng-select [items]="_bride_for" bindLabel="code" bindValue="value" [multiple]="false"
                            placeholder="Select Vender" [(ngModel)]="_bridge_obj['bridge_type']"
                            (change)="inputFields()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="true" name="project_cd">
                          </ng-select>
                        </div>
                      </div>
                      <br *ngIf="isCulvert" />
                      <div class="row" *ngIf="isCulvert">
                        <div class="col-3 text-left">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Type of Culvert Bridge<span class="text-danger">*</span>
                          </h6>
                        </div>
                        <div class="col-1">:</div>

                        <div class="col-2">
                          <ng-select [items]="_types_of_culvert" bindLabel="value" bindValue="code" [multiple]="false"
                            placeholder="Select Clvert Type" [(ngModel)]="_bridge_obj['culvert_type']"
                            (change)="inputFields()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="true" name="project_cd">
                          </ng-select>
                        </div>
                        <div class="col-6">

                        </div>
                      </div>
                      <br>
                      <hr [hidden]="!feilds_flag">
                      <form (ngSubmit)="bridgeSecondNext(stepper)">
                        <div [hidden]="feilds_flag">


                          <div class="row">
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                            <div class="col-2 text-center">
                              <h6 id="h" style="color: brown; min-height: 20px;">
                                For Foundation
                              </h6>
                            </div>
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                          </div>
                          <br>

                          <div *ngFor="let item of _culvert_input_foundation_arr; index as i">
                            <div class="row">
                              <div class="col-3 text-left">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1">:</div>
                              <div class="col-2">
                                <input type="Number" class="form-control" placeholder="{{item[i] | Split}}"
                                  [(ngModel)]="_foundation_obj[item[i]]" (keyup)="trunc(item[i])"
                                  name="item {{ i }}value1" #input1Ref="ngModel" required>

                                <div *ngIf="submitted && input1Ref.invalid" class="text-danger">
                                  <div *ngIf="input1Ref.errors?.['required']">{{item[i] | Split}}</div>
                                </div>
                              </div>
                              <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i+1] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1" [hidden]="item[i+1]=='extra_item'">:</div>
                              <div class="col-2" [hidden]="item[i+1]=='extra_item'">
                                <input type="Number" class="form-control" placeholder="{{item[i+1] | Split}}"
                                  [(ngModel)]="_foundation_obj[item[i+1]]" name="item {{ i }}value2"
                                  #input2Ref="ngModel" (keyup)="trunc(item[i+1])" required>
                                <div *ngIf="submitted && input2Ref.invalid" class="text-danger">
                                  <div *ngIf="input2Ref.errors?.['required']">{{item[i+1] | Split}}</div>
                                </div>
                              </div>
                            </div>
                            <br>
                          </div>

                          <br>
                          <div class="row">
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                            <div class="col-2 text-center">
                              <h6 id="h" style="color: brown; min-height: 20px;">
                                For Sub - Structure
                              </h6>
                            </div>
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                          </div>
                          <br>



                          <div *ngFor="let item of _culvert_input_sub_str_arr; index as i">
                            <div class="row">
                              <div class="col-3 text-left">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1">:</div>
                              <div class="col-2">
                                <input type="Number" class="form-control" placeholder="{{item[i] | Split}}"
                                  [(ngModel)]="_sub_str_obj[item[i]]" name="item{{ i }}value1" #input3Ref="ngModel"
                                  required>
                                <div *ngIf="submitted && input3Ref.invalid" class="text-danger">
                                  <div *ngIf="input3Ref.errors?.['required']">{{item[i] | Split}}</div>
                                </div>
                              </div>
                              <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i+1] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1" [hidden]="item[i+1]=='extra_item'">:</div>
                              <div class="col-2" [hidden]="item[i+1]=='extra_item'">
                                <input type="Number" class="form-control" placeholder="{{item[i+1] | Split}}"
                                  [(ngModel)]="_sub_str_obj[item[i+1]]" name="item{{ i }}value2" #input4Ref="ngModel"
                                  required>
                                <div *ngIf="submitted && input4Ref.invalid" class="text-danger">
                                  <div *ngIf="input4Ref.errors?.['required']">{{item[i+1] | Split}}</div>
                                </div>
                              </div>
                            </div>
                            <br>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                            <div class="col-2 text-center">
                              <h6 id="h" style="color: brown; min-height: 20px;">
                                For Super - Structure
                              </h6>
                            </div>
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                          </div>
                          <br>



                          <div *ngFor="let item of _culvert_input_super_str_arr; index as i">
                            <div class="row">
                              <div class="col-3 text-left">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1">:</div>
                              <div class="col-2">
                                <input type="Number" class="form-control" placeholder="{{item[i] | Split}}"
                                  [(ngModel)]="_super_str_obj[item[i]]" name="item{{ i }}value1" #input5Ref="ngModel"
                                  required>
                                <div *ngIf="submitted && input5Ref.invalid" class="text-danger">
                                  <div *ngIf="input5Ref.errors?.['required']">{{item[i] | Split}}</div>
                                </div>
                              </div>
                              <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i+1] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1" [hidden]="item[i+1]=='extra_item'"> :</div>
                              <div class="col-2" [hidden]="item[i+1]=='extra_item'">
                                <input type="Number" class="form-control" placeholder="{{item[i+1] | Split}}"
                                  [(ngModel)]="_super_str_obj[item[i+1]]" name="item{{ i }}value2" #input6Ref="ngModel"
                                  required>
                                <div *ngIf="submitted && input6Ref.invalid" class="text-danger">
                                  <div *ngIf="input6Ref.errors?.['required']">{{item[i+1] | Split}}</div>
                                </div>
                              </div>
                            </div>
                            <br>
                          </div>





                          <div class="row">
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                            <div class="col-2 text-center">
                              <h6 id="h" style="color: brown; min-height: 20px;">
                                For Protection Work
                              </h6>
                            </div>
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                          </div>
                          <br>
                          <div *ngFor="let item of _culvert_input_protection_work_arr; index as i">
                            <div class="row">
                              <div class="col-3 text-left">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1">:</div>
                              <div class="col-2">
                                <input type="Number" class="form-control" placeholder="{{item[i] | Split}}"
                                  [(ngModel)]="_protection_work_obj[item[i]]" name="item{{ i }}value1"
                                  #input7Ref="ngModel" required>
                                <div *ngIf="submitted && input7Ref.invalid" class="text-danger">
                                  <div *ngIf="input7Ref.errors?.['required']">{{item[i] | Split}}</div>
                                </div>
                              </div>
                              <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i+1] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1" [hidden]="item[i+1]=='extra_item'">:</div>
                              <div class="col-2" [hidden]="item[i+1]=='extra_item'">
                                <input type="Number" class="form-control" placeholder="{{item[i+1] | Split}}"
                                  [(ngModel)]="_protection_work_obj[item[i+1]]" name="item{{ i }}value2"
                                  #input8Ref="ngModel" required>
                                <div *ngIf="submitted && input8Ref.invalid" class="text-danger">
                                  <div *ngIf="input8Ref.errors?.['required']">{{item[i+1] | Split}}</div>
                                </div>
                              </div>
                            </div>
                            <br>

                          </div>

                        </div>


                        <br><br>
                        <div class="row">
                          <div class="col-12 text-center">
                            <button mat-button matStepperPrevious
                              class="btn btn-outline-primary text-center">Back</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button mat-button type="submit" class="btn btn-outline-primary text-center">Next</button>
                          </div>
                        </div>
                      </form>

                    </fieldset>

                  </div>

                  <div [hidden]="_road_est_flag">
                    <fieldset>
                      <br>
                      <h6 class="text-danger">Please fill all the required field which is mark as asterisk ( * )</h6>
                      <br>
                      <div class="row">
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                        <div class="col-2 text-center">
                          <h6 id="h" style="color: brown; min-height: 20px;">
                            Road Estimate
                          </h6>
                        </div>
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col-3 text-left">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Type of Road<span class="text-danger">*</span>
                          </h6>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-2">
                          <ng-select [items]="_type_of_road" bindLabel="code" bindValue="value" [multiple]="false"
                            placeholder=" Type of Road" [(ngModel)]="_road_obj['road_location']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            (change)="roadType()" name="project_cd">
                          </ng-select>
                        </div>

                        <div class="col-3 text-left" *ngIf="_road_flag">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Select Technique <span class="text-danger">*</span>
                          </h6>
                        </div>
                        <div class="col-1" *ngIf="_road_flag">:</div>
                        <div class="col-2 text-left" *ngIf="_road_flag">
                          <ng-select [items]="_technique_for_road" bindLabel="code" bindValue="value" [multiple]="false"
                            placeholder="Select Technique" [(ngModel)]="_road_obj['road_technique']"
                            (change)="inputFieldsForRoad()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="true" name="project_cd">
                          </ng-select>
                        </div>

                      </div>
                      <br>
                      <hr [hidden]="!feilds_flag">
                      <form (ngSubmit)="roadSecondNext(stepper)">
                        <div [hidden]="feilds_flag">
                          <div class="row">
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                            <div class="col-2 text-center">
                              <h6 id="h" style="color: brown; min-height: 20px;">
                                Input For {{rode_type}}
                              </h6>
                            </div>
                            <div class="col-5">
                              <hr class="hrline">
                            </div>
                          </div>
                          <br />
                          <div *ngFor="let item of _appr_road_input_for_old_arr; index as i">
                            <div class="row">
                              <div class="col-3 text-left">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1">:</div>
                              <div class="col-2">
                                <input type="Number" class="form-control" placeholder="{{item[i] | Split}}"
                                  [(ngModel)]="_input_approach_road_obj[item[i]]" (keyup)="trunc(item[i])"
                                  name="item {{ i }}value1" #input1Ref="ngModel" required>
                                <div *ngIf="submitted && input1Ref.invalid" class="text-danger">
                                  <div *ngIf="input1Ref.errors?.['required']">{{item[i] | Split}}</div>
                                </div>
                              </div>
                              <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                                <h6 style="margin: 2% 0% 0% 2%">
                                  {{item[i+1] | Split}}<span class="text-danger">*</span>
                                </h6>
                              </div>
                              <div class="col-1" [hidden]="item[i+1]=='extra_item'">:</div>
                              <div class="col-2" [hidden]="item[i+1]=='extra_item'">
                                <input type="Number" class="form-control" placeholder="{{item[i+1] | Split}}"
                                  [(ngModel)]="_input_approach_road_obj[item[i+1]]" name="item {{ i }}value2"
                                  #input2Ref="ngModel" (keyup)="trunc(item[i+1])" required>
                                <div *ngIf="submitted && input2Ref.invalid" class="text-danger">
                                  <div *ngIf="input2Ref.errors?.['required']">{{item[i+1] | Split}}</div>
                                </div>
                              </div>
                            </div>
                            <br>
                          </div>
                          <br />
                          <hr>
                          <div class="row">
                            <div class="col-12 text-center">
                              <button mat-button matStepperPrevious
                                class="btn btn-outline-primary text-center">Back</button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <button mat-button type="submit" class="btn btn-outline-primary text-center">Next</button>
                            </div>
                          </div>
                          <br>
                        </div>
                      </form>
                      <br><br>
                    </fieldset>
                  </div>


                </mat-step>
                <mat-step [stepControl]="third" label="{{_step_third_label}}">

                  <div [hidden]="_general_est_flag">
                    <br>
                    <div class="row">
                      <div class="col-3"></div>
                      <br>
                      <div class="col-3">Total Amount for Extra Items (Rs): </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" placeholder="164778.95"
                          [(ngModel)]="obj['extraAmount']" disabled>

                      </div>
                      <div class="col-3"></div>
                    </div>
                    <br>
                    <div class="row">

                      <div class="col-12 text-right">
                        <button class=" btn btn-outline-primary" (click)="addExtraItem()"><i class="fa fa-plus"
                            aria-hidden="true"></i></button>
                      </div>
                    </div>
                    <br>

                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                    </mat-form-field>
                    <br>
                    <div class="example-container">

                      <table mat-table [dataSource]="extraItemDatasource" matSort>
                        <ng-container matColumnDef="sr_no">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Sr. No.
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element; let i=index;" class="right">
                            {{i+1}} </td>
                        </ng-container>


                        <ng-container matColumnDef="item_code">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Item Code


                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element  ;let i=index;" class="right">

                            <input type="text" class="form-control" placeholder="Enter Item Code"
                              [(ngModel)]="extraItemarr[i]['item_code']">
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="item_type">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Item Type
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element; let i=index;" class="right">
                            <ng-select [multiple]="false" placeholder="Select Item Type"
                              [items]="mainService.codeValueTechObj['EMB065']" bindLabel="value" bindValue="code"
                              [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                              name="hierarchy" [(ngModel)]="extraItemarr[i]['item_type']">
                            </ng-select>
                          </td>
                        </ng-container>



                        <ng-container matColumnDef="item_desc">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Item Description
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="text" class="form-control" placeholder="Enter Item Description"
                              [(ngModel)]="extraItemarr[i]['item_desc']">
                          </td>
                        </ng-container>


                        <ng-container matColumnDef="qty_unit">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Quantity unit
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <ng-select [multiple]="false" placeholder="Select Quantity unit"
                              [items]="mainService.codeValueTechObj['EMB059']" bindLabel="value" bindValue="code"
                              [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                              name="hierarchy" [(ngModel)]="extraItemarr[i]['qty_unit']">
                            </ng-select>
                          </td>
                        </ng-container>


                        <ng-container matColumnDef="qty">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Quantity
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="number" class="form-control" placeholder="Enter  Quantity"
                              [(ngModel)]="extraItemarr[i]['qty']" (change)="calculateExtraAmt(i)">
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="costing_unit">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Costing Unit</th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <ng-select [multiple]="false" placeholder="Select Costing Unit"
                              [items]="mainService.codeValueTechObj['EMB067']" bindLabel="value" bindValue="code"
                              [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                              name="hierarchy" [(ngModel)]="extraItemarr[i]['costing_unit']">
                            </ng-select>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="costing_rate">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Costing Rate</th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="number" class="form-control" placeholder="Enter Costing Rate"
                              [(ngModel)]="extraItemarr[i]['costing_rate']" (change)="calculateExtraAmt(i)">
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Amount (Rs.)</th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="number" class="form-control" [(ngModel)]="extraItemarr[i]['amount']" disabled>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Actions </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <button class="btn btn-outline-danger" (click)="deleteExtraItemRow(i)">
                              {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK15']}}
                            </button>
                          </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedExtraItemsColumns">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: displayedExtraItemsColumns;">
                        </tr>
                      </table>

                    </div>
                    <br><br>
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button matStepperPrevious class="btn btn-outline-primary text-center">Back</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button mat-button class="btn btn-outline-primary text-center"
                          (click)="nextThirdStep(stepper)">Next</button>
                      </div>
                    </div>



                  </div>




                  <div [hidden]="_bridge_est_flag">

                    <fieldset>
                      <br>
                      <div class="row">
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                        <div class="col-2 text-center">
                          <h6 id="h" style="color: brown; min-height: 20px;">
                            {{_report_main_hedding}}
                          </h6>
                        </div>
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-3 text-left">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Type of Bridge
                          </h6>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-2">
                          {{_bridge_obj['bridge_location']}}
                        </div>

                        <div class="col-3 text-left" *ngIf="_bridge_flag">
                          <h6 style="margin: 2% 0% 0% 2%">
                            {{_bridge_type_hidding}}
                          </h6>
                        </div>
                        <div class="col-1" *ngIf="_bridge_flag">:</div>
                        <div class="col-2 text-left" *ngIf="_bridge_flag">
                          {{_bridge_obj['bridge_type']}}
                        </div>

                      </div>
                      <br>
                      <hr [hidden]="!feilds_flag">

                      <div [hidden]="feilds_flag">


                        <div class="row">
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                          <div class="col-2 text-center">
                            <h6 id="h" style="color: brown; min-height: 20px;">
                              For Foundation
                            </h6>
                          </div>
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                        </div>
                        <br>

                        <div *ngFor="let item of _re_culvert_foundation_arr; index as i">
                          <div class="row" *ngIf="_re_culvert_foundation_obj[item[i]] != undefined ; else heading">
                            <div class="col-3 text-left">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="_re_culvert_foundation_obj[item[i]] == undefined">
                              :</div>
                            <div class="col-2 text-center">
                              {{_re_culvert_foundation_obj[item[i]]}}
                            </div>
                            <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i+1] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="item[i+1]=='extra_item'">:</div>
                            <div class="col-2 text-center" [hidden]="item[i+1]=='extra_item'">
                              {{_re_culvert_foundation_obj[item[i+1]]}}
                            </div>
                          </div>
                          <ng-template #heading>
                            <div class="col-12 text-center" [style.color]="'grey'">
                              <h6>
                                {{item[i] | Split}}
                              </h6>
                            </div>
                          </ng-template>
                          <br>
                        </div>





                        <br>
                        <div class="row">
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                          <div class="col-2 text-center">
                            <h6 id="h" style="color: brown; min-height: 20px;">
                              For Sub - Structure
                            </h6>
                          </div>
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                        </div>
                        <br>



                        <div *ngFor="let item of _re_culvert_sub_str_arr; index as i">
                          <div class="row" *ngIf="_re_culvert_sub_str_obj[item[i]] != undefined ; else heading">
                            <div class="col-3 text-left">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="_re_culvert_sub_str_obj[item[i]] == undefined">:
                            </div>
                            <div class="col-2 text-center">
                              {{_re_culvert_sub_str_obj[item[i]]}}
                            </div>
                            <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i+1] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="item[i+1]=='extra_item'">:</div>
                            <div class="col-2 text-center" [hidden]="item[i+1]=='extra_item'">
                              {{_re_culvert_sub_str_obj[item[i+1]]}}
                            </div>
                          </div>

                          <ng-template #heading>
                            <div class="col-12" [style.color]="'grey'">
                              <h6>
                                {{item[i] | Split}}
                              </h6>
                            </div>
                          </ng-template>
                          <br>
                        </div>



                        <br>
                        <div class="row">
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                          <div class="col-2 text-center">
                            <h6 id="h" style="color: brown; min-height: 20px;">
                              For Super - Structure
                            </h6>
                          </div>
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                        </div>
                        <br>



                        <div *ngFor="let item of _re_culvert_super_str_arr; index as i">
                          <div class="row" *ngIf="_re_culvert_super_str_obj[item[i]] != undefined ; else heading">
                            <div class="col-3 text-left">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="_re_culvert_super_str_obj[item[i]] == undefined">:
                            </div>
                            <div class="col-2 text-center">
                              {{_re_culvert_super_str_obj[item[i]]}}
                            </div>
                            <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i+1] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="item[i+1]=='extra_item'">:</div>
                            <div class="col-2 text-center" [hidden]="item[i+1]=='extra_item'">
                              {{_re_culvert_super_str_obj[item[i+1]]}}
                            </div>
                          </div>
                          <ng-template #heading>
                            <div class="col-12 text-center" [style.color]="'grey'">
                              <h6>
                                {{item[i] | Split}}
                              </h6>
                            </div>
                          </ng-template>
                          <br>
                        </div>

                        <div class="row">
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                          <div class="col-2 text-center">
                            <h6 id="h" style="color: brown; min-height: 20px;">
                              For Protection Work
                            </h6>
                          </div>
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                        </div>
                        <br>
                        <div *ngFor="let item of _re_culvert_protection_arr; index as i">
                          <div class="row" *ngIf="_re_culvert_protection_work_obj[item[i]] != undefined ; else heading">
                            <div class="col-3 text-left">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center"
                              [hidden]="_re_culvert_protection_work_obj[item[i]] == undefined">
                              :</div>
                            <div class="col-2 text-center">
                              {{_re_culvert_protection_work_obj[item[i]]}}
                            </div>
                            <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i+1] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="item[i+1]=='extra_item'">:</div>
                            <div class="col-2 text-center" [hidden]="item[i+1]=='extra_item'">
                              {{_re_culvert_protection_work_obj[item[i+1]]}}
                            </div>
                          </div>
                          <ng-template #heading>
                            <div class="col-12 text-center" [style.color]="'grey'">
                              <h6>
                                {{item[i] | Split}}
                              </h6>
                            </div>
                          </ng-template>
                          <br>
                        </div>


                      </div>




                      <br><br>
                      <div class="row">
                        <div class="col-12 text-center">
                          <button mat-button matStepperPrevious
                            class="btn btn-outline-primary text-center">Back</button>
                          &nbsp;&nbsp;&nbsp;&nbsp; <button mat-button (click)="inputPrint()"
                            class="btn btn-outline-primary text-center">Download</button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <button mat-button class="btn btn-outline-primary text-center"
                            (click)="brigethirdNext(stepper)">Next</button>
                        </div>
                      </div>



                    </fieldset>



                  </div>

                  <div [hidden]="_road_est_flag">
                    <fieldset>
                      <br>
                      <div class="row">
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                        <div class="col-2 text-center">
                          <h6 id="h" style="color: brown; min-height: 20px;">
                            Road Estimate
                          </h6>
                        </div>
                        <div class="col-5">
                          <hr class="hrline">
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-3 text-left">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Type of Road
                          </h6>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-2">
                          {{rode_type}}
                        </div>

                        <div class="col-3 text-left">
                          <h6 style="margin: 2% 0% 0% 2%">
                            Type of Technique
                          </h6>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-2">
                          {{this._road_obj['road_technique']}}
                        </div>
                      </div>
                      <br>
                      <hr [hidden]="!feilds_flag">

                      <div [hidden]="feilds_flag">
                        <div class="row">
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                          <div class="col-2 text-center">
                            <h6 id="h" style="color: brown; min-height: 20px;">
                              {{_report_main_hedding_for_road}}
                            </h6>
                          </div>
                          <div class="col-5">
                            <hr class="hrline">
                          </div>
                        </div>
                        <br>

                        <div *ngFor="let item of _old_tech_appr_road_arr; index as i">
                          <div class="row" *ngIf="_old_tech_appr_road_arr[item[i]] != undefined;">
                            <!-- else heading -->
                            <div class="col-3 text-left">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="_old_tech_appr_road_arr[item[i]] == undefined">
                              :</div>
                            <div class="col-2 text-center">
                              {{_old_tech_appr_road_arr[item[i]]}}
                            </div>
                            <div class="col-3 text-left" [hidden]="item[i+1]=='extra_item'">
                              <h6 style="margin: 2% 0% 0% 2%">
                                {{item[i+1] | Split}}
                              </h6>
                            </div>
                            <div class="col-1 text-center" [hidden]="item[i+1]=='extra_item'">:</div>
                            <div class="col-2 text-center" [hidden]="item[i+1]=='extra_item'">
                              {{_old_tech_appr_road_arr[item[i+1]]}}
                            </div>
                          </div>
                          <!-- <ng-template #heading>
                                            <div class="col-12 text-center" [style.color]="'grey'">
                                              <h6>
                                                {{item[i] | Split}}
                                              </h6>
                                            </div>
                                          </ng-template> -->
                          <br>
                        </div>
                        <br>
                      </div>
                      <br><br>
                      <div class="row">
                        <div class="col-12 text-center">
                          <button mat-button matStepperPrevious
                            class="btn btn-outline-primary text-center">Back</button>
                          &nbsp;&nbsp;&nbsp;&nbsp; <button mat-button (click)="inputPrintForRoad()"
                            class="btn btn-outline-primary text-center">Download</button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <button mat-button class="btn btn-outline-primary text-center"
                            (click)="roadThirdNext(stepper)">Next</button>
                        </div>
                      </div>
                    </fieldset>

                  </div>

                </mat-step>
                <mat-step [stepControl]="fourth" label="{{_step_forth_label}}">
                  <div [hidden]="_general_est_flag">
                    <br>
                    <div class="row">
                      <div class="col-3"></div>
                      <br>
                      <div class="col-3">Total Amount for Scheduled Items (Rs) : </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['scheduledItemsAmount']"
                          disabled>

                      </div>
                      <div class="col-3"></div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3"></div>
                      <br>
                      <div class="col-3">Total Amount for Extra Items (Rs) : </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['extraAmount']" disabled>

                      </div>
                      <div class="col-3"></div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3"></div>
                      <br>
                      <div class="col-3">Total Amount (Rs) : </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['totalAmount']" disabled>

                      </div>
                      <div class="col-3"></div>
                    </div>
                    <br>
                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                    </mat-form-field>

                    <br>

                    <div class="example-container">
                      <div class="row">

                        <div class="col-12 text-right">
                          <button class="btn btn-outline-primary" (click)="addOtherChargesRow()"><i class="fa fa-plus"
                              aria-hidden="true"></i></button>
                        </div>
                      </div>
                      <br>

                      <table mat-table [dataSource]="otherChargesDs" matSort>

                        <ng-container matColumnDef="sr_no">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Sr. No.
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element; let i=index;" class="right">
                            {{i+1}} </td>
                        </ng-container>


                        <ng-container matColumnDef="opreator">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Opreator


                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element  ;let i=index;" class="right">

                            <ng-select [multiple]="false" placeholder="Select Item Type" [items]="add_ded"
                              bindLabel="code" bindValue="value" [selectableGroup]="true"
                              [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                              [(ngModel)]="otherChargesarr[i]['opreator']">
                            </ng-select>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Type
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element; let i=index;" class="right">
                            <ng-select [multiple]="false" placeholder="Select Item Type"
                              [items]="mainService.codeValueTechObj['EMB068']" bindLabel="value" bindValue="code"
                              [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                              name="hierarchy" [(ngModel)]="otherChargesarr[i]['type']">
                            </ng-select>
                          </td>
                        </ng-container>



                        <ng-container matColumnDef="desc">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Description
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="text" class="form-control" placeholder="Enter Item Description"
                              [(ngModel)]="otherChargesarr[i]['desc']">
                          </td>
                        </ng-container>


                        <ng-container matColumnDef="method">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Method
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <ng-select [multiple]="false" placeholder="Select Quantity unit"
                              [items]="mainService.codeValueTechObj['EMB069']" bindLabel="value" bindValue="code"
                              [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                              name="hierarchy" [(ngModel)]="otherChargesarr[i]['method']"
                              (change)="checkValueType(otherChargesarr[i]['method'],i)">
                            </ng-select>
                          </td>
                        </ng-container>


                        <ng-container matColumnDef="value">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Value / Percentage
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="number" class="form-control" placeholder="Enter  Quantity"
                              [(ngModel)]="otherChargesarr[i]['cal_on_value']"
                              (change)="otherChargeCal(otherChargesarr[i]['method'],i,'','')">
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="col_on">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Calculated on
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <ng-select *ngIf="otherChargesarr[i]['col_on_flag']==true" [multiple]="false"
                              placeholder="Select Costing Unit" [items]="col_on_cd" bindLabel="value" bindValue="code"
                              [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                              name="hierarchy" [(ngModel)]="otherChargesarr[i]['cal_on']"
                              (change)="otherChargeCal(otherChargesarr[i]['method'], i,otherChargesarr[i]['cal_on'],'')">
                            </ng-select>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="col_amt">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Calculated Amount (Rs)
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <input type="number" class="form-control" placeholder="Enter Costing Rate"
                              [(ngModel)]="otherChargesarr[i]['amount']" disabled>
                          </td>
                        </ng-container>


                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Actions </th>
                          <td mat-cell flex-stretch *matCellDef="let element;let i=index;" class="right">
                            <button class="btn btn-outline-danger"
                              (click)="deleteOtherChargeRow(otherChargesarr[i]['method'],i,otherChargesarr[i]['cal_on'],'html')">
                              {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK15']}}
                            </button>
                          </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedOtherChargesColumns">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: displayedOtherChargesColumns;">
                        </tr>
                      </table>
                      <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                      </mat-paginator> -->
                    </div>
                    <br>
                    <br>
                    <div class="row">
                      <div class="col-6"></div>

                      <div class="col-3">Total Addition (+) :
                      </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['totalAmtAdd']" disabled>

                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6"></div>

                      <div class="col-3">Total Deduction (-) :
                      </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['totalAmtSub']" disabled>

                      </div>
                    </div>


                    <div class="row">
                      <div class="col-6"></div>

                      <div class="col-3">Final Amount (Rs) :
                      </div>
                      <div class="col-3">

                        <input type="text" name="" id="" class="form-control" [(ngModel)]="obj['finalAmt']" disabled>

                      </div>
                    </div>
                    <br>
                    <br><br>
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button matStepperPrevious class="btn btn-outline-primary text-center">Back</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button mat-button class="btn btn-outline-primary text-center"
                          (click)="nextvalue(stepper)">Next</button>
                      </div>
                    </div>

                  </div>
                  <div [hidden]="_bridge_est_flag">
                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-12 text-center">
                        <h4 style="margin-top: 8px;">Details of Measurement</h4>
                      </div>
                    </div>
                    <div class="row border-right border-left border-secondary"
                      style="margin-left:0px; margin-right:0px">
                      <div class="col-2">Work Name</div>
                      <div class="col-1">:</div>
                      <div class="col-9 text-left">{{Obj['work_name']}}</div>
                    </div>
                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-1">Sr No.</div>
                      <div class="col-2">Ref. to Morth&H specifi.</div>
                      <div class="col-1"></div>
                      <div class="col-2">Item Description</div>
                      <div class="col-4">
                        <div class="col-12" style="text-align: center;">Measurement</div>
                        <div class="col-12 d-flex">
                          <div class="col-3">No</div>
                          <div class="col-3">L</div>
                          <div class="col-3">B</div>
                          <div class="col-3">H</div>
                        </div>
                      </div>
                      <div class="col-1">Quantity</div>
                      <div class="col-1">Unit</div>
                    </div>
                    <div *ngFor="let mesuredItems of all_items ; index as j">
                      <div *ngIf="j==0">
                        <div class="row text-center border-left border-right border-bottom border-secondary"
                          style="margin-left:0px; margin-right:0px">
                          <div class="col-12 text-center" style="margin-top: 8px;">
                            <h5 style="margin-top: 8px;"> 1. BRIDGE</h5>
                          </div>
                        </div>

                      </div>

                      <div *ngIf="j==0">
                        <div class="row text-center border-left border-right border-bottom border-secondary"
                          style="margin-left:0px; margin-right:0px">
                          <div class="col-12">
                            <h5 style="margin-top: 8px;"> A - FOUNDATION</h5>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="j==1">
                        <div class="row text-center border border-secondary" style="margin-left:0px; margin-right:0px">
                          <div class="col-12  ">
                            <h5 style="margin-top: 8px;"> B - SUB STRUCTURE</h5>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="j==2">
                        <div class="row text-center border-left border-right border-bottom border-secondary"
                          style="margin-left:0px; margin-right:0px">
                          <div class="col-12 ">
                            <h5 style="margin-top: 8px;">C - SUPER STRUCTURE</h5>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="j==3">
                        <div class="row text-center border-left border-right border-bottom border-secondary"
                          style="margin-left:0px; margin-right:0px">
                          <div class="col-12 t ">
                            <h5 style="margin-top: 8px;">2. PROTECTION WORK</h5>
                          </div>
                        </div>

                      </div>

                      <div *ngFor="let item of mesuredItems; index as i"
                        class="border-left border-right border-secondary" style="margin-left:0px; margin-right:0px">
                        <div class="row" *ngIf="mesuredItems[i]['filter']=='uperLine'"
                          style="margin-left:0px; margin-right:0px"
                          [ngClass]="{'border-bottom border-secondary':mesuredItems[i]['No']!=undefined}">
                          <div class="col-1" style="padding-left: 14px;">
                            {{mesuredItems[i]['sno']}}</div>
                          <div class="col-2">{{mesuredItems[i]['ref_to_morth']}}</div>
                          <div class="col-1">{{mesuredItems[i]['level_cd']}}</div>
                          <div class="col-2">{{mesuredItems[i]['item_desc']}}</div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['No']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['No']}}</div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['L']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['L']}} </div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['B']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['B']}} </div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['H/D']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['H/D']}}</div>
                          <div class="col-1" [hidden]="mesuredItems[i]['QTY']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['QTY']}}</div>
                          <div class="col-1" [hidden]="mesuredItems[i]['Unit']==undefined">
                            {{mesuredItems[i]['Unit']}}</div>

                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='middleLine'">


                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-1">
                              <span *ngIf="mesuredItems[i]['newKey']=='bold'">
                                <strong>{{mesuredItems[i]['level_cd']}}</strong>
                              </span>
                              <span *ngIf="mesuredItems[i]['newKey']!='bold'">
                                {{mesuredItems[i]['level_cd']}}
                              </span>

                              <!-- {{mesuredItems[i]['level_cd']}} -->
                            </div>
                            <div class="col-2" style="padding-left: 15px">
                              <span *ngIf="mesuredItems[i]['newKey']=='bold'">
                                <strong>{{mesuredItems[i]['item_desc']}}</strong>
                              </span>
                              <span *ngIf="mesuredItems[i]['newKey']!='bold'">
                                {{mesuredItems[i]['item_desc']}}
                              </span>
                              <!-- {{mesuredItems[i]['item_desc']}} -->

                            </div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['No']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['L']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['B']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['H/D']}}</div>
                            <div class="col-1 " style="word-wrap: break-word;">
                              {{mesuredItems[i]['QTY']}}</div>
                            <div class="col-1">{{mesuredItems[i]['Unit']}}</div>
                          </div>
                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='lowerLine' &&   mesuredItems[i]['toShow'] != 'no'"
                          [ngStyle]="{'border-bottom':gettotalborder(mesuredItems[i])}">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-3 text-center">
                              <h6>{{mesuredItems[i]['TotalKey']}}</h6>

                            </div>
                            <div class="col-1 ">
                            </div>
                            <div class="col-1"></div>
                            <div class="col-1"></div>
                            <div class="col-1"></div>
                            <div class="col-1" style="word-wrap: break-word;">
                              <h6>{{mesuredItems[i]['Total']}}</h6>
                            </div>
                            <div class="col-1">
                              <h6>{{mesuredItems[i]['Unit']}}</h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='After_decuction'"
                          [ngStyle]="{'border-bottom':getafterdedborder(mesuredItems[i])}">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-3"></div>
                            <div class="col-2 text-right">
                              <h6>After Decuction</h6>
                            </div>
                            <div class="col-3">
                              <h6>{{mesuredItems[i]['After_decuction']}}</h6>
                            </div>
                            <div class="col-1">
                              <h6>{{mesuredItems[i]['Unit']}}</h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='sub_total'">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-3"></div>
                            <div class="col-2 text-right">
                              <h6> Sub Total</h6>
                            </div>
                            <div class="col-3">
                              <h6>{{mesuredItems[i]['sub_total']}}</h6>
                            </div>
                            <div class="col-1">
                              <h6>{{mesuredItems[i]['Unit']}}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>


                    <div class="row">

                      <div class="col-12 text-center">
                        <button mat-button matStepperPrevious class="btn btn-outline-primary text-center">Back</button>
                        &nbsp;&nbsp;&nbsp;&nbsp; <button mat-button (click)="measurementPrint()"
                          class="btn btn-outline-primary text-center">Download</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="bridgeForthNext(stepper)">Next</button>
                        <!-- <button class="btn btn-outline-primary"
                                                  (click)="measurement()">Submit</button> -->
                      </div>
                    </div>
                    <br>
                  </div>
                  <div [hidden]="_road_est_flag">
                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-12 text-center">
                        <h4 style="margin-top: 8px;">Details of Measurement</h4>
                      </div>
                    </div>
                    <div class="row border-right border-left border-secondary"
                      style="margin-left:0px; margin-right:0px">
                      <div class="col-2">Work Name</div>
                      <div class="col-1">:</div>
                      <div class="col-9 text-left">{{Obj['work_name']}}</div>
                    </div>
                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-1">Sr No.</div>
                      <div class="col-2">Ref. to Morth&H specifi.</div>
                      <!-- <div class="col-1"></div> -->
                      <div class="col-2">Item Description</div>
                      <div class="col-4">
                        <div class="col-12" style="text-align: center;">Measurement</div>
                        <div class="col-12 d-flex">
                          <div class="col-3">No</div>
                          <div class="col-3">L</div>
                          <div class="col-3">B</div>
                          <div class="col-3">H</div>
                        </div>
                      </div>
                      <div class="col-1">Quantity</div>
                      <div class="col-1">Unit</div>
                    </div>
                    <div *ngFor="let mesuredItems of all_items ; index as j">

                      <div *ngFor="let item of mesuredItems; index as i"
                        class="border-left border-right border-secondary" style="margin-left:0px; margin-right:0px">
                        <div class="row" *ngIf="mesuredItems[i]['filter']=='uperLine'"
                          style="margin-left:0px; margin-right:0px"
                          [ngClass]="{'border-bottom border-secondary':mesuredItems[i]['No']!=undefined}">
                          <div class="col-1" style="padding-left: 14px;">
                            {{mesuredItems[i]['sno']}}</div>
                          <div class="col-2">{{mesuredItems[i]['ref_to_morth']}}</div>
                          <!-- <div class="col-1">{{mesuredItems[i]['level_cd']}}</div> -->
                          <div class="col-2">{{mesuredItems[i]['level_cd'] }}{{mesuredItems[i]['item_desc']}}</div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['No']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['No']}}</div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['L']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['L']}} </div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['B']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['B']}} </div>
                          <div class="col-1 text-center" [hidden]="mesuredItems[i]['H/D']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['H/D']}}</div>
                          <div class="col-1" [hidden]="mesuredItems[i]['QTY']==undefined"
                            style="word-wrap: break-word;">
                            {{mesuredItems[i]['QTY']}}</div>
                          <div class="col-1" [hidden]="mesuredItems[i]['Unit']==undefined">
                            {{mesuredItems[i]['Unit']}}</div>

                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='middleLine'">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <!-- <div class="col-1"></div> -->
                            <div class="col-2" style="padding-left: 15px; ">
                              {{mesuredItems[i]['item_desc']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['No']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['L']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['B']}}</div>
                            <div class="col-1 text-center" style="word-wrap: break-word;">
                              {{mesuredItems[i]['H/D']}}</div>
                            <div class="col-1 " style="word-wrap: break-word;">
                              {{mesuredItems[i]['QTY']}}</div>
                            <div class="col-1">{{mesuredItems[i]['Unit']}}</div>
                          </div>
                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='lowerLine'"
                          [ngStyle]="{'border-bottom':gettotalborder(mesuredItems[i])}">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-3 text-center">
                              <h6>{{mesuredItems[i]['TotalKey']}}</h6>
                            </div>
                            <div class="col-1 ">
                            </div>
                            <div class="col-1"></div>
                            <div class="col-1"></div>
                            <div class="col-1"></div>
                            <div class="col-1" style="word-wrap: break-word;">
                              <h6>{{mesuredItems[i]['Total']}}</h6>
                            </div>
                            <div class="col-1">
                              <h6>{{mesuredItems[i]['Unit']}}</h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='After_decuction'"
                          [ngStyle]="{'border-bottom':getafterdedborder(mesuredItems[i])}">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-3"></div>
                            <div class="col-2 text-right">
                              <h6>After Decuction</h6>
                            </div>
                            <div class="col-3">
                              <h6>{{mesuredItems[i]['After_decuction']}}</h6>
                            </div>
                            <div class="col-1">
                              <h6>{{mesuredItems[i]['Unit']}}</h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="mesuredItems[i]['filter']=='sub_total'">
                          <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"></div>
                            <div class="col-3"></div>
                            <div class="col-2 text-right">
                              <h6> Sub Total</h6>
                            </div>
                            <div class="col-3">
                              <h6>{{mesuredItems[i]['sub_total']}}</h6>
                            </div>
                            <div class="col-1">
                              <h6>{{mesuredItems[i]['Unit']}}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />

                      <div class="row">
                        <div class="col-12 text-center">
                          <button mat-button matStepperPrevious
                            class="btn btn-outline-primary text-center">Back</button>
                          &nbsp;&nbsp;&nbsp;&nbsp; <button mat-button (click)="measurementPrintForRoad()"
                            class="btn btn-outline-primary text-center">Download</button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <button class="btn btn-outline-primary" (click)="roadForthNext(stepper)">Next</button>
                        </div>
                      </div>
                    </div>
                    <br>
                  </div>
                </mat-step>


                <mat-step [stepControl]="fifth" label="{{_step_fifth_label}}">
                  <div [hidden]="_general_est_flag">
                    <fieldset>
                      <legend>
                        <h6> Basic Details</h6>
                      </legend>
                      <div class="row">
                        <div class="col-3">
                          <h6>Project Path : </h6>
                        </div>
                        <div class="col-3">{{Obj['path_desc']}}</div>
                        <div class="col-3">
                          <h6>Budget Path : </h6>
                        </div>
                        <div class="col-3">{{budpath}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <h6>Budget Head : </h6>
                        </div>
                        <div class="col-3">{{Obj['budget_cd']}}</div>
                        <div class="col-3">
                          <h6>Budget Amount (Rs) :</h6>
                        </div>
                        <div class="col-3">{{Obj['budget_amt']}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <h6>Estimate ID : </h6>
                        </div>
                        <div class="col-3">{{Obj['est_id_p']}}</div>
                        <div class="col-3">
                          <h6>Estimate Description : </h6>
                        </div>
                        <div class="col-3">{{Obj['est_desc']}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <h6>Name of Work : </h6>
                        </div>
                        <div class="col-3">{{Obj['work_name']}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <h6>Funds To Use : </h6>
                        </div>
                        <div class="col-3">{{Obj['fund_use']}}</div>
                        <div class="col-3">
                          <h6>Estimate Date: :</h6>
                        </div>
                        <div class="col-3">{{Obj['est_date']}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <h6>Remark : </h6>
                        </div>
                        <div class="col-3">{{Obj['est_remark']}}</div>
                      </div>

                    </fieldset>
                    <fieldset>
                      <legend>
                        <h6>Items Estimate & Other Charges</h6>
                      </legend>
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>Total Amount for Scheduled Items (Rs) : </h6>
                        </div>
                        <div class="col-6">{{obj['scheduledItemsAmount']}}</div>

                      </div>
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>Total Amount for Extra Items (Rs) : </h6>
                        </div>
                        <div class="col-6">{{obj['extraAmount']}}</div>

                      </div>
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>Total Amount (Rs):</h6>
                        </div>
                        <div class="col-6">{{obj['totalAmount']}}</div>

                      </div>
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>Final Amount (Rs) : </h6>
                        </div>
                        <div class="col-6">{{obj['finalAmt']}}</div>
                      </div>
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>Cost of Estimate (Rs) : </h6>
                        </div>
                        <div class="col-6">{{obj['finalAmt']}}</div>
                      </div>

                    </fieldset>
                    <br>
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button matStepperPrevious class="btn btn-outline-primary text-center">Back</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;

                        <button mat-button matStepperNext class="btn btn-outline-primary text-center" (click)="submit()"
                          *ngIf="create_flag">Submit</button>
                        <button mat-button matStepperNext class="btn btn-outline-primary text-center" (click)="update()"
                          *ngIf="update_flag">Update</button>
                      </div>
                    </div>
                  </div>
                  <!-- <button (click)="getAnalysisItems()">get Data </button> -->
                  <div [hidden]="_bridge_est_flag">
                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-12 text-center">
                        <h5 style="margin-top: 8px;">ANALYSIS OF RATES</h5>
                      </div>
                    </div> 

                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-1">Sr No.</div>
                      <div class="col-1">Ref. to Morth&H specifi.</div>
                      <div class="col-1">Level Code</div>
                      <div class="col-3">Item Description</div>
                      <div class="col-1">Unit</div>
                      <div class="col-1">Quantity</div>
                      <div class="col-2">Rate(Rs.)</div>
                      <div class="col-2">Amount</div>
                      <div class="row border border-secondary" *ngFor="let  itemObj of _ana_items; index as j"
                        style="margin-left:0px; margin-right:0px">
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">{{itemObj['sno']}}</div>
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">{{itemObj['ref_to_morth']}} </div>
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">{{itemObj['level_cd']}} </div>
                        <div class="col-3" *ngIf="itemObj['item_desc']!=null && itemObj['item_desc2'] !=null">
                          {{itemObj['item_desc2']}}
                          <span
                            *ngIf="itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit'">
                            {{itemObj['quantity']}} % On {{_ana_items[j]['rate'] | number:'1.2-2' }}
                          </span>
                        </div>
                        <div class="col-2"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                          {{itemObj['item_desc']}} </div>
                        <div class="col-1"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                        </div>
                        <div class="col-1"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                        </div>
                        <div class="col-1"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                        </div>

                        <div class="col-1" *ngIf="itemObj['item_desc']!=null"> {{itemObj['item_unit']}}</div>
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">
                          <span
                            *ngIf="!(itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit' || itemObj['item_desc']=='Royality' || itemObj['isFw'] == 1)">
                            {{itemObj['quantity']}} </span>
                        </div>

                        <!-- <div class="col-2" *ngIf="itemObj['item_unit'] ==null && (itemObj['item_desc']!='Overhead charges' || itemObj['item_desc'] !='Contractors profit') && itemObj['item_desc'] != null && itemObj['quantity'] != null ;else other_content"><input type="number" [(ngModel)]="_ana_items[j]['rate']" class="form-control" placeholder="Enter rate" (keyup)="calcute(j)"> </div> -->


                        <div class="col-2"
                          *ngIf="(itemObj['item_unit']!=null || itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit') || (itemObj['item_unit'] ==null && (itemObj['item_desc']!='Overhead charges' || itemObj['item_desc'] !='Contractors profit') && itemObj['item_desc'] != null && itemObj['quantity'] != null)  ;else other_content">

                          <span
                            *ngIf="!(itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit' || itemObj['item_desc']=='Royality' || itemObj['isFw'] == 1)">
                            <input type="number" [(ngModel)]="_ana_items[j]['rate']" class="form-control" placeholder=0
                              (change)="calcute(j)"
                              [disabled]="itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit' || itemObj['item_desc']=='Royality' || itemObj['isFw'] == 1"
                              *ngIf=" itemObj['item_desc']!='Royality'">
                            <!-- insert rate -->
                          </span>

                        </div>

                        <ng-template #other_content></ng-template>
                        <div class="col-2" *ngIf="itemObj['item_desc']!=null ">
                          <span *ngIf="itemObj['item_desc']!= 'Royality'"> {{itemObj['amount']}} </span>
                          <span *ngIf="itemObj['item_desc'] == 'Royality'"> {{itemObj['quantity']}} </span>


                        </div>


                        <!-- <div class="col-2" *ngIf="itemObj['item_desc']!=null && itemObj['id']==null">
                                                                  {{itemObj['item_desc']}} = {{itemObj['amount']}}</div> -->


                        <div class="col-3" *ngIf="itemObj['item_desc']==null">
                          <p class="_hr"></p>
                        </div>
                        <div class="col-3" *ngIf="itemObj['item_desc']==null">Unit = {{itemObj['unit']}}
                          <br>
                          Taking Output = {{itemObj['tak_op']}} {{itemObj['unit']}}
                          <!-- <p class="_hr2"></p> -->

                        </div>
                        <div class="col-6" *ngIf="itemObj['item_desc']==null"> </div>

                      </div>
                    </div>

                    <br>

                    <div class="row">
                      <div class="col-12 text-center">
                        <button class="btn btn-outline-primary" matStepperPrevious>Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-outline-primary"
                          (click)="anaPrint()">Download</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button mat-button class="btn btn-outline-primary text-center"
                          (click)="boqNext(stepper)">Next</button>

                      </div>
                    </div>
                  </div>

                  <div [hidden]="_road_est_flag">
                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-12 text-center">
                        <h5 style="margin-top: 8px;">ANALYSIS OF RATES</h5>
                      </div>
                    </div>

                    <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                      <div class="col-1">Sr No.</div>
                      <div class="col-1">Ref. to Morth&H specifi.</div>
                      <div class="col-1">Level Code</div>
                      <div class="col-3">Item Description</div>
                      <div class="col-1">Unit</div>
                      <div class="col-1">Quantity</div>
                      <div class="col-2">Rate(Rs.)</div>
                      <div class="col-2">Amount</div>
                      <div class="row border border-secondary" *ngFor="let  itemObj of _ana_items; index as j"
                        style="margin-left:0px; margin-right:0px">
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">{{itemObj['sno']}}</div>
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">{{itemObj['ref_to_morth']}} </div>
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">{{itemObj['level_cd']}} </div>
                        <div class="col-3" *ngIf="itemObj['item_desc']!=null && itemObj['item_desc2'] !=null">
                          {{itemObj['item_desc2']}}
                          <span
                            *ngIf="itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit'">
                            {{itemObj['quantity']}} % On {{_ana_items[j]['rate'] | number:'1.2-2'}}
                          </span>
                        </div>
                        <div class="col-2"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                          {{itemObj['item_desc']}} </div>
                        <div class="col-1"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                        </div>
                        <div class="col-1"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                        </div>
                        <div class="col-1"
                          *ngIf="itemObj['item_desc']!=null && itemObj['id']==null && itemObj['item_desc2'] ==null ">
                        </div>

                        <div class="col-1" *ngIf="itemObj['item_desc']!=null"> {{itemObj['item_unit']}}</div>
                        <div class="col-1" *ngIf="itemObj['item_desc']!=null">
                          <span
                            *ngIf="!(itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit' || itemObj['item_desc']=='Royality' || itemObj['isFw'] == 1)">
                            {{itemObj['quantity']}} </span>
                        </div>

                        <!-- <div class="col-2" *ngIf="itemObj['item_unit'] ==null && (itemObj['item_desc']!='Overhead charges' || itemObj['item_desc'] !='Contractors profit') && itemObj['item_desc'] != null && itemObj['quantity'] != null ;else other_content"><input type="number" [(ngModel)]="_ana_items[j]['rate']" class="form-control" placeholder="Enter rate" (keyup)="calcute(j)"> </div> -->


                        <div class="col-2"
                          *ngIf="(itemObj['item_unit']!=null || itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit') || (itemObj['item_unit'] ==null && (itemObj['item_desc']!='Overhead charges' || itemObj['item_desc'] !='Contractors profit') && itemObj['item_desc'] != null && itemObj['quantity'] != null)  ;else other_content">

                          <span
                            *ngIf="!(itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit' || itemObj['item_desc']=='Royality' || itemObj['isFw'] == 1)">
                            <input type="number" [(ngModel)]="_ana_items[j]['rate']" class="form-control" placeholder=0
                              (change)="calcute(j)"
                              [disabled]="itemObj['item_desc']=='Overhead charges' || itemObj['item_desc']=='Contractors profit' || itemObj['item_desc']=='Royality' || itemObj['isFw'] == 1"
                              *ngIf=" itemObj['item_desc']!='Royality'">
                            <!-- insert rate -->
                          </span>

                        </div>

                        <ng-template #other_content></ng-template>
                        <div class="col-2" *ngIf="itemObj['item_desc']!=null ">
                          <span *ngIf="itemObj['item_desc']!= 'Royality'"> {{itemObj['amount']}} </span>
                          <span *ngIf="itemObj['item_desc'] == 'Royality'"> {{itemObj['quantity']}} </span>


                        </div>


                        <!-- <div class="col-2" *ngIf="itemObj['item_desc']!=null && itemObj['id']==null">
                                                                {{itemObj['item_desc']}} = {{itemObj['amount']}}</div> -->


                        <div class="col-3" *ngIf="itemObj['item_desc']==null">
                          <p class="_hr"></p>
                        </div>
                        <div class="col-3" *ngIf="itemObj['item_desc']==null">Unit = {{itemObj['unit']}}
                          <br>
                          Taking Output = {{itemObj['tak_op']}} {{itemObj['unit']}}
                          <!-- <p class="_hr2"></p> -->

                        </div>
                        <div class="col-6" *ngIf="itemObj['item_desc']==null"> </div>

                      </div>
                    </div>

                    <br>

                    <div class="row">
                      <div class="col-12 text-center">
                        <button class="btn btn-outline-primary" matStepperPrevious>Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-outline-primary"
                          (click)="anaPrint()">Download</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button mat-button class="btn btn-outline-primary text-center"
                          (click)="boqNext(stepper)">Next</button>
                      </div>
                    </div>
                  </div>
                </mat-step>


                <!-- 6. Bill of Quantity of Bridge (Bridge) -->
                <mat-step [stepControl]="six" label="{{_step_six_label}}" *ngIf="!_bridge_est_flag">
                  <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                    <div class="col-12 text-center">
                      <h5 style="margin-top: 8px;"> Bill of Quantity of Bridge</h5>
                    </div>
                  </div>

                  <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                    <div class="col-1">No.</div>
                    <div class="col-1">Sr No.</div>
                    <div class="col-1">Ref. to Morth&H specifi.</div>
                    <div class="col-1"> </div>
                    <div class="col-2">Item Description</div>
                    <div class="col-1">Unit</div>
                    <div class="col-1">Quantity</div>
                    <div class="col-2">Rate(Rs.)</div>
                    <div class="col-2">Amount</div>
                  </div>

                  <div *ngFor="let item of boq_items; index as i" class="row border border-secondary"
                    style="margin-left:0px; margin-right:0px">
                    <div class="col-1">{{i+1}}</div>
                    <div class="col-1">{{item['sno']}}</div>
                    <div class="col-1">{{item['ref_to_morth']}}</div>
                    <div class="col-1"> </div>
                    <div class="col-2">{{item['item_desc']}}</div>
                    <div class="col-1">{{item['Unit']}}</div>
                    <div class="col-1">{{item['qty']}}</div>
                    <div class="col-2">{{item['rate']}}</div>
                    <div class="col-2">{{item['amount']}}</div>


                    <tr *ngIf="item['isNested']" style = "background-color: white ; border : none" >
                      <!-- class="row border border-secondary" style="margin-left:0px; margin-right:0px" -->
                      <div>
                        <div class="col-1"> </div>
                        <div class="col-1"></div>
                        <div class="col-1"></div>
                        <div class="col-1"> </div>
                        <div class="col-2"></div>
                        <div class="col-1"></div>
                        <div class="col-1"></div>
                        <div class="col-2"></div>
                        <div class="col-2"></div>
                      </div>


                      <div *ngFor="let subItem of item['subitems']" class="row border border-secondary"
                      style="margin-left:0px; margin-right:0px">
                        <div class="col-1"></div>
                        <div class="col-1"></div>
                        <div class="col-1"></div>
                        <div class="col-1"></div>
                        <div class="col-2">{{subItem['name']}}</div>
                        <div class="col-1">CUM</div>
                        <div class="col-1">{{subItem['value']}} </div>
                        <div class="col-2">{{item['rate']}}</div>
                        <div class="col-2">{{(item['rate'] * subItem['value']) | number: '1.2-2'}}</div>

                      </div>

                    </tr>
                  </div>
                  <br>
                  <div class="col-12 text-center">
                    <button class="btn btn-outline-primary" matStepperPrevious>Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-outline-primary"
                      (click)="boqPrint()">Download</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-outline-primary" (click)="estimateBridge()">Submit</button>
                  </div>
                </mat-step>


                <!-- 6. Bill of Quantity of Road (Road) -->
                <mat-step [stepControl]="six" label="{{_step_six_label}}" *ngIf="!_road_est_flag">
                  <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                    <div class="col-12 text-center">
                      <h5 style="margin-top: 8px;"> Bill of Quantity of Bridge</h5>
                    </div>
                  </div>

                  <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                    <div class="col-1">Sr No.</div>
                    <div class="col-1">Ref. to Morth&H specifi.</div>
                    <div class="col-1"> </div>
                    <div class="col-3">Item Description</div>
                    <div class="col-1">Unit</div>
                    <div class="col-1">Quantity</div>
                    <div class="col-2">Rate(Rs.)</div>
                    <div class="col-2">Amount</div>
                  </div>

                  <div *ngFor="let item of boq_items; index as i" class="row border border-secondary"
                    style="margin-left:0px; margin-right:0px">
                    <div class="col-1">{{item['sno']}}</div>
                    <div class="col-1">{{item['ref_to_morth']}}</div>
                    <div class="col-1"> </div>
                    <div class="col-3">{{item['item_desc']}}</div>
                    <div class="col-1">{{item['Unit']}}</div>
                    <div class="col-1">{{item['qty']}}</div>
                    <div class="col-2">{{item['rate']}}</div>
                    <div class="col-2">{{item['amount']}}</div>
                  </div>
                  <br>
                  <div class="col-12 text-center">
                    <button class="btn btn-outline-primary" matStepperPrevious>Back</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-outline-primary"
                      (click)="boqPrint()">Download</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-outline-primary" (click)="estimateRoad()">Submit</button>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="red" type="ball-circus">
  <p style="color: green"> Loading Please Wait .... </p>
</ngx-spinner>

<div class="modal" id="sorPopUp">
  <div class="modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          <h5> SOR Items</h5>
        </h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3">Total Amount for SOR Items (Rs):</div>
            <div class="col-3">

              <input type="text" class="form-control" placeholder="670.00" [(ngModel)]="estimateCal['sor_tot']">
            </div>
            <div class="col-3"></div>
          </div>
          <br><br>

          <div id="tab-1">

            <div class="row">

              <div class="col-6 text-right">
                <button class="btn btn-warning" (click)="addRowSORItems()"><i class="fa fa-plus"
                    aria-hidden="true"></i></button>
              </div>
            </div>
            <br>
            <div>
              <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                <div style="width: 6%;" class="text-center">

                  <h6><b>S No.</b></h6>

                </div>
                <div style="width: 7%;" class="text-center">
                  <h6><b>SOR Source</b></h6>


                </div>
                <div style="width: 7%;" class="text-center">
                  <h6><b> Chapter</b></h6>


                </div>

                <div style="width: 7%;" class="text-center">
                  <h6><b> SI No. </b></h6>


                </div>
                <div style="width: 7%;" class="text-center">
                  <h6><b>Group</b></h6>


                </div>

                <div style="width: 6%;" class="text-center">
                  <h6><b> Sub Group </b></h6>


                </div>
                <div style="width: 14%;" class="text-center">
                  <h6><b>Item Description</b></h6>
                </div>


                <div style="width: 3%;" class="text-center">
                  <h6><b>Qty</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Unit</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Add / Delete</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Excis Duty</b></h6>
                </div>

                <div style="width: 5%;" class="text-center">
                  <h6><b>VAT</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Friegh Charge</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Any Other Taxes / Duties / Levies</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Rate</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Amount (Rs)</b></h6>
                </div>

                <div style="width: 5%;" class="text-center">
                  <h6><b>Action</b></h6>
                </div>

                <br>
              </div>
              <br><br>
              <div *ngFor="let ln of addRowSorItems; let i = index;">
                <div class="row">
                  <div class="text-center" style="width: 6%;">

                    <h6><b> {{ln['item_id']}}.</b></h6>
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>


                  &nbsp;&nbsp;
                  <div style="width: 6%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 14%;">
                    <input type="text" class="form-control" placeholder="Item Description" [(ngModel)]="ln['code_no']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 3%;">
                    <input type="number" class="form-control" placeholder="Quantity" [(ngModel)]="ln['costing_rate']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Unit" [(ngModel)]="ln['costing_rate']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="number" class="form-control" placeholder="Add / Delete" [(ngModel)]="ln['item_qty']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="number" class="form-control" placeholder="Excise Duity" [(ngModel)]="ln['amount']">

                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="BAT" [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Friegh Charge"
                      [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Any Other Taxes / Duties / Levies "
                      [(ngModel)]="ln['amount_in_word']">

                  </div>


                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Rate" [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Amount (Rs)"
                      [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;

                  <div style="width: 5%;">
                    <button class="btn btn-danger " (click)="deleteRowSORItems(i)"><i class="fa fa-trash"
                        aria-hidden="true"></i></button>
                  </div>
                </div>
                <hr>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" (click)="saveRowSORItems()">Save</button>&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>

</div>

<div class="modal" id="dsrPopUp">
  <div class="modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          <h5> DSR Items</h5>
        </h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3">Total Amount for DSR Items (Rs):</div>
            <div class="col-3">

              <input type="text" class="form-control" placeholder="670.00" [(ngModel)]="estimateCal['dsr_tot']">
            </div>
            <div class="col-3"></div>
          </div>
          <br><br>

          <div id="tab-1">

            <div class="row">

              <div class="col-6 text-right">
                <button class="btn btn-warning" (click)="addRowDSRItems()"><i class="fa fa-plus"
                    aria-hidden="true"></i></button>
              </div>
            </div>
            <br>
            <div>
              <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                <div style="width: 6%;" class="text-center">

                  <h6><b>S No.</b></h6>

                </div>
                <div style="width: 7%;" class="text-center">
                  <h6><b>SOR Source</b></h6>


                </div>
                <div style="width: 7%;" class="text-center">
                  <h6><b> Chapter</b></h6>


                </div>

                <div style="width: 7%;" class="text-center">
                  <h6><b> SI No. </b></h6>


                </div>
                <div style="width: 7%;" class="text-center">
                  <h6><b>Group</b></h6>


                </div>

                <div style="width: 6%;" class="text-center">
                  <h6><b> Sub Group </b></h6>


                </div>
                <div style="width: 14%;" class="text-center">
                  <h6><b>Item Description</b></h6>
                </div>


                <div style="width: 3%;" class="text-center">
                  <h6><b>Qty</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Unit</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Add / Delete</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Excis Duty</b></h6>
                </div>

                <div style="width: 5%;" class="text-center">
                  <h6><b>VAT</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Friegh Charge</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Any Other Taxes / Duties / Levies</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Rate</b></h6>
                </div>
                <div style="width: 5%;" class="text-center">
                  <h6><b>Amount (Rs)</b></h6>
                </div>

                <div style="width: 5%;" class="text-center">
                  <h6><b>Action</b></h6>
                </div>

                <br>
              </div>
              <br><br>
              <div *ngFor="let ln of addRowSorItems; let i = index;">
                <div class="row">
                  <div class="text-center" style="width: 6%;">

                    <h6><b> {{ln['item_id']}}.</b></h6>
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 7%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>


                  &nbsp;&nbsp;
                  <div style="width: 6%;">
                    <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                      placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 14%;">
                    <input type="text" class="form-control" placeholder="Item Description" [(ngModel)]="ln['code_no']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 3%;">
                    <input type="text" class="form-control" placeholder="Quantity" [(ngModel)]="ln['costing_rate']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Unit" [(ngModel)]="ln['costing_rate']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="number" class="form-control" placeholder="Add / Delete" [(ngModel)]="ln['item_qty']">
                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Excise Duity" [(ngModel)]="ln['amount']">

                  </div>
                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="BAT" [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Friegh Charge"
                      [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Any Other Taxes / Duties / Levies "
                      [(ngModel)]="ln['amount_in_word']">

                  </div>


                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Rate" [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;
                  <div style="width: 5%;">
                    <input type="text" class="form-control" placeholder="Amount (Rs)"
                      [(ngModel)]="ln['amount_in_word']">

                  </div>

                  &nbsp;&nbsp;

                  <div style="width: 5%;">
                    <button class="btn btn-danger "><i class="fa fa-trash" aria-hidden="true"></i></button>
                  </div>
                </div>
                <hr>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" (click)="saveRowDSRItems()">Save</button>&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>

</div>

<div class="modal" id="morthPopUp">
  <div class="modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          <h5> MoRTH Items</h5>
        </h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <!-- <div class="container"> -->
        <div class="row">
          <div class="col-3"></div>
          <div class="col-3">Total Amount for Morth Items (Rs):</div>
          <div class="col-3">

            <input type="text" class="form-control" placeholder="670.00" [(ngModel)]="estimateCal['sor_tot']">
          </div>
          <div class="col-3"></div>
        </div>
        <br><br>

        <div id="tab-1">

          <div class="row">

            <div class="col-6 text-right">
              <button class="btn btn-warning" (click)="addRowMoRTHItems()"><i class="fa fa-plus"
                  aria-hidden="true"></i></button>
            </div>
          </div>
          <br>
          <div>
            <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
              <div style="width: 4%;" class="text-center">

                <h6><b>S No.</b></h6>

              </div>
              <div style="width: 7%;" class="text-center">
                <h6><b>SOR Source</b></h6>


              </div>
              <div style="width: 7%;" class="text-center">
                <h6><b> Chapter</b></h6>


              </div>

              <div style="width: 7%;" class="text-center">
                <h6><b> SI No. </b></h6>


              </div>
              <div style="width: 7%;" class="text-center">
                <h6><b>Group</b></h6>


              </div>

              <div style="width: 6%;" class="text-center">
                <h6><b> Sub Group </b></h6>


              </div>
              <div style="width: 14%;" class="text-center">
                <h6><b>Item Description</b></h6>
              </div>


              <div style="width: 3%;" class="text-center">
                <h6><b>Qty</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Unit</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Add / Delete</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Excis Duty</b></h6>
              </div>

              <div style="width: 5%;" class="text-center">
                <h6><b>VAT</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Friegh Charge</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Any Other Taxes / Duties / Levies</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Rate</b></h6>
              </div>
              <div style="width: 5%;" class="text-center">
                <h6><b>Amount (Rs)</b></h6>
              </div>

              <div style="width: 5%;" class="text-center">
                <h6><b>Action</b></h6>
              </div>

              <br>
            </div>
            <br><br>
            <div *ngFor="let ln of addRowSorItems; let i = index;">
              <div class="row">
                <div class="text-center" style="width: 6%;">

                  <h6><b> {{ln['item_id']}}.</b></h6>
                </div>
                &nbsp;&nbsp;
                <div style="width: 7%;">
                  <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                    placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>

                &nbsp;&nbsp;
                <div style="width: 7%;">
                  <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                    placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>

                &nbsp;&nbsp;
                <div style="width: 7%;">
                  <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                    placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>

                &nbsp;&nbsp;
                <div style="width: 7%;">
                  <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                    placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>


                &nbsp;&nbsp;
                <div style="width: 6%;">
                  <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id" [multiple]="false"
                    placeholder="Select Item" [(ngModel)]="ln['id']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>

                &nbsp;&nbsp;
                <div style="width: 14%;">
                  <input type="text" class="form-control" placeholder="Item Description" [(ngModel)]="ln['code_no']">
                </div>
                &nbsp;&nbsp;
                <div style="width: 3%;">
                  <input type="text" class="form-control" placeholder="Quantity" [(ngModel)]="ln['costing_rate']">
                </div>
                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="Unit" [(ngModel)]="ln['costing_rate']">
                </div>
                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="number" class="form-control" placeholder="Add / Delete" [(ngModel)]="ln['item_qty']">
                </div>
                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="Excise Duity" [(ngModel)]="ln['amount']">

                </div>
                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="BAT" [(ngModel)]="ln['amount_in_word']">

                </div>

                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="Friegh Charge"
                    [(ngModel)]="ln['amount_in_word']">

                </div>

                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="Any Other Taxes / Duties / Levies "
                    [(ngModel)]="ln['amount_in_word']">

                </div>


                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="Rate" [(ngModel)]="ln['amount_in_word']">

                </div>

                &nbsp;&nbsp;
                <div style="width: 5%;">
                  <input type="text" class="form-control" placeholder="Amount (Rs)" [(ngModel)]="ln['amount_in_word']">

                </div>

                &nbsp;&nbsp;

                <div style="width: 5%;">
                  <button class="btn btn-danger "><i class="fa fa-trash" aria-hidden="true"></i></button>
                </div>
              </div>
              <hr>
            </div>
            <br>
            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-primary" (click)="saveRowMoRTHItems()">Save</button>&nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>

        <!-- </div> -->

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>

</div>
<div class="modal" id="select44">
  <!-- for crete modal for prod -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Product Herirarchy :</h4>
        <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div>
          <div>


            <div class="row" *ngFor="let item of planing_arrprod,let i =index">
              <div class="col-1"></div>
              <div class="col-3" *ngIf="i==0">
                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                  Hierarchy16']}} <span style="color: red;"> *</span></h6>
                <!-- Select Node : -->
              </div>
              <div class="col-3" *ngIf="i!=0"></div>
              <div class="col-2">
                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                  Hierarchy17']}} </span>{{item['level']}}
                <!-- Level -  -->
              </div>
              <div class="col-3">
                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="code"
                  [multiple]="false"
                  placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                  [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setprodHierarchyNode(item,i)"
                  [selectableGroupAsModel]="true" [closeOnSelect]="true">
                </ng-select>
                <!-- Select  Node -->
              </div>
              <div class="col-3" *ngIf="i==planing_arrprod.length-1">
                <button (click)="add_dropdown_prod(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                    aria-hidden="true"></i></button> &nbsp;&nbsp;
                <button class="btn btn-danger">
                  <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
              </div>
              <div class="col-3"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-2 ">
                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB
                  Hierarchy19']}} <span style="color: red;">*</span>
                  <!-- Current Node Path : -->
                </h6>
              </div>
              <div class="col-9">
                {{prodpath}}
              </div>
            </div>
            <br>
            <hr>


            <br>
            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-primary" (click)="submitprod()">
                  {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy26']}}</button>
                <!-- Submit -->
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="ACTHIER">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Select Project</h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="row" *ngFor="let item of planing_arrproj,let i =index">
          <div class="col-3"></div>
          <div class="col-3" *ngIf="i==0">
            <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB
              Hierarchy16']}} <span style="color: red;"> *</span></h6>
            <!-- Select Node : -->
          </div>
          <div class="col-3" *ngIf="i!=0"></div>
          <div class="col-1">

            <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
              Hierarchy17']}} </span>{{item['level']}}
            <!-- Level -  -->
          </div>
          <div class="col-3">
            <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd"
              [multiple]="false"
              placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
              [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)"
              [selectableGroupAsModel]="true" [closeOnSelect]="true">
            </ng-select>
            <!-- Select  Node -->
          </div>
          <div class="col-2" *ngIf="i==planing_arrproj.length-1">
            <button (click)="down(item,i)" class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                aria-hidden="true"></i></button> &nbsp;&nbsp;
            <button (click)="remove(item,i)" class="btn btn-outline-danger">
              <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
          </div>
          <div class="col-3"></div>
        </div>
        <br>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-3 ">
            <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB
              Hierarchy19']}} <span style="color: red;">*</span>
              <!-- Current Node Path : -->
            </h6>
          </div>
          <div class="col-6">
            {{Obj['path_desc']}}
          </div>

        </div>
        <br>


        <!-- Modal footer -->
        <div class="modal-footer">
          <button class="btn btn-outline-primary" (click)="submit_act()">Submit</button>
          <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">CLOSE</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal" id="select33">
  <!-- for crete modal for budget -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Budget Herirarchy :</h4>
        <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div>
          <div>


            <div class="row" *ngFor="let item of planing_arr,let i =index">
              <div class="col-1"></div>
              <div class="col-3" *ngIf="i==0">
                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                  Hierarchy16']}} <span style="color: red;"> *</span></h6>
                <!-- Select Node : -->
              </div>
              <div class="col-3" *ngIf="i!=0"></div>
              <div class="col-2">
                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                  Hierarchy17']}} </span>{{item['level']}}
                <!-- Level -  -->
              </div>
              <div class="col-3">
                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="code"
                  [multiple]="false"
                  placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                  [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNode(item,i)"
                  [selectableGroupAsModel]="true" [closeOnSelect]="true">
                </ng-select>
                <!-- Select  Node -->
              </div>
              <div class="col-3" *ngIf="i==planing_arr.length-1">
                <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                    aria-hidden="true"></i></button> &nbsp;&nbsp;
                <button (click)="remove_dropdown(item,i)" class="btn btn-outline-danger">
                  <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
              </div>
              <div class="col-3"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-2 ">
                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB
                  Hierarchy19']}} <span style="color: red;">*</span>
                  <!-- Current Node Path : -->
                </h6>
              </div>
              <div class="col-9">
                {{budpath}}
              </div>
            </div>
            <br>



            <br>
            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-outline-primary" (click)="submitbud()">
                  {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy26']}}</button>
                <!-- Submit -->
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<!-- ----------------------------------------------------------Un Used Code start ------------------------------------->


<!-- <div class="tab-content">
                      <div id="tab-1">

                          <div class="row">
                              <div class="col-6 text-right">
                                  <h5><b>Items</b></h5>

                              </div>
                              <div class="col-6 text-right">
                                  <button class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus"
                                          aria-hidden="true"></i></button>
                              </div>
                          </div>
                          <br>
                          <div>
                              <div class="row"
                                  style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                  <div style="width: 6%;" class="text-center">

                                      <h6><b>S No.</b></h6>

                                  </div>
                                  <div style="width: 16%;" class="text-center">
                                      <h6><b>Select Item </b></h6>


                                  </div>
                                  <div style="width: 14%;" class="text-center">
                                      <h6><b> Code</b></h6>


                                  </div>

                                  <div style="width: 9%;" class="text-center">
                                      <h6><b> Rate </b></h6>


                                  </div>
                                  <div style="width: 12%;" class="text-center">
                                      <h6><b>Quantity</b></h6>


                                  </div>

                                  <div style="width: 12%;" class="text-center">
                                      <h6><b>Amount</b></h6>


                                  </div>
                                  <div style="width: 22%;" class="text-center">
                                      <h6><b>Amount in words</b></h6>


                                  </div>

                                  <div style="width: 5%;" class="text-center">
                                      <h6><b></b></h6>
                                  </div>

                                  <br>
                              </div>
                              <br><br>
                              <div *ngFor="let ln of estimateRow; let i = index;">
                                  <div class="row">
                                      <div class="text-center" style="width: 6%;">


                                          <h6><b> {{ln['item_id']}}.</b></h6>
                                      </div>
                                      &nbsp;&nbsp;
                                      <div style="width: 16%;">
                                          <ng-select [items]="item_list" bindLabel="item_desc" bindValue="id"
                                              [multiple]="false" placeholder="Select Item"
                                              [(ngModel)]="ln['id']" [selectableGroup]="true"
                                              [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                              (change)="setData(ln['id'] , i)">
                                          </ng-select>
                                      </div>
                                      &nbsp;&nbsp;
                                      <div style="width: 14%;">
                                          <input type="text" class="form-control" placeholder="Code"
                                              [(ngModel)]="ln['code_no']" disabled>
                                      </div>
                                      &nbsp;&nbsp;
                                      <div style="width: 9%;">
                                          <input type="text" class="form-control" placeholder="Rate"
                                              [(ngModel)]="ln['costing_rate']" disabled>
                                      </div>
                                      &nbsp;&nbsp;
                                      <div style="width: 12%;">
                                          <input type="number" class="form-control" placeholder="Quantity"
                                              [(ngModel)]="ln['item_qty']" (change)="toWords(ln['item_qty'] , i)"
                                              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">

                                      </div>
                                      &nbsp;&nbsp;
                                      <div style="width: 12%;">
                                          <input type="text" class="form-control" placeholder="Amount"
                                              [(ngModel)]="ln['amount']" disabled>

                                      </div>
                                      &nbsp;&nbsp;
                                      <div style="width: 17%;">
                                          <input type="text" class="form-control" placeholder="Amount in Words"
                                              [(ngModel)]="ln['amount_in_word']"
                                              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                              disabled>

                                      </div>
                                      &nbsp;&nbsp;

                                      <div style="width: 5%;">
                                          <button class="btn btn-danger " (click)="deleteRow(i)"><i
                                                  class="fa fa-trash" aria-hidden="true"></i></button>
                                      </div>
                                  </div>
                                  <hr>
                              </div>
                              <br>
                              <div class="row">
                                  <div class="col-12 text-center">
                                      <button class="btn btn-primary" (click)="save()">Save</button>&nbsp;&nbsp;
                                  </div>
                              </div>
                          </div>
                      </div>
                       </div> -->
<div class="modal fade" id="est_docs" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Estimte document</h6>
        </h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">




        </div>




        <div class="row justify-content-center">

          <img [src]="imgURL5" height="500px" width="700px" />


        </div>





      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="view_dtl" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Estimte Details</h6>
        </h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <fieldset>
          <legend>
            <h6> Basic Details</h6>
          </legend>
          <div class="row">
            <div class="col-3">
              <h6>Project Path : </h6>
            </div>
            <div class="col-3">{{Obj['path_desc']}}</div>
            <div class="col-3">
              <h6>Budget Path : </h6>
            </div>
            <div class="col-3">{{budpath}}</div>
          </div>
          <div class="row">
            <div class="col-3">
              <h6>Budget Head : </h6>
            </div>
            <div class="col-3">{{Obj['budget_cd']}}</div>
            <div class="col-3">
              <h6>Budget Amount (Rs) :</h6>
            </div>
            <div class="col-3">{{Obj['budget_amt']}}</div>
          </div>
          <div class="row">
            <div class="col-3">
              <h6>Estimate ID : </h6>
            </div>
            <div class="col-3">{{Obj['est_id_p']}}</div>
            <div class="col-3">
              <h6>Estimate Description : </h6>
            </div>
            <div class="col-3">{{Obj['est_desc']}}</div>
          </div>
          <div class="row">
            <div class="col-3">
              <h6>Name of Work : </h6>
            </div>
            <div class="col-3">{{Obj['work_name']}}</div>
            <!-- <div class="col-3"><h6>Project Name :</h6></div>
              <div class="col-3">{{budpath}}</div> -->
          </div>
          <div class="row">
            <div class="col-3">
              <h6>Funds To Use : </h6>
            </div>
            <div class="col-3">{{Obj['fund_use']}}</div>
            <div class="col-3">
              <h6>Estimate Date: :</h6>
            </div>
            <div class="col-3">{{Obj['est_date']}}</div>
          </div>
          <div class="row">
            <div class="col-3">
              <h6>Remark : </h6>
            </div>
            <div class="col-3">{{Obj['est_remark']}}</div>
            <!-- <div class="col-3"><h6>Estimate Date: :</h6></div>
              <div class="col-3">{{budpath}}</div> -->
          </div>

        </fieldset>
        <fieldset>
          <legend>
            <h6>Items Estimate & Other Charges</h6>
          </legend>
          <div class="row">
            <div class="col-6 text-right">
              <h6>Total Amount for Scheduled Items (Rs) : </h6>
            </div>
            <div class="col-6">{{Obj['scheduledItemsAmount']}}</div>

          </div>
          <div class="row">
            <div class="col-6 text-right">
              <h6>Total Amount for Extra Items (Rs) : </h6>
            </div>
            <div class="col-6">{{Obj['extraAmount']}}</div>

          </div>
          <div class="row">
            <div class="col-6 text-right">
              <h6>Total Amount (Rs):</h6>
            </div>
            <div class="col-6">{{Obj['totalAmount']}}</div>

          </div>
          <!-- <div class="row">
              <div class="col-6 text-right"><h6>Add GST @18% on Total Amount : </h6></div>
              <div class="col-6">{{Obj['work_name']}}</div>

          </div> -->
          <!-- <div class="row">
              <div class="col-6 text-right"><h6>Less Labour Cess @1% on Total Amount : </h6></div>
              <div class="col-6">{{Obj['fund_use']}}</div>

          </div> -->
          <div class="row">
            <div class="col-6 text-right">
              <h6>Final Amount (Rs) : </h6>
            </div>
            <div class="col-6">{{Obj['finalAmt']}}</div>
            <!-- <div class="col-3"><h6>Estimate Date: :</h6></div>
              <div class="col-3">{{budpath}}</div> -->
          </div>
          <div class="row">
            <div class="col-6 text-right">
              <h6>Cost of Estimate (Rs) : </h6>
            </div>
            <div class="col-6">{{Obj['finalAmt']}}</div>
            <!-- <div class="col-3"><h6>Estimate Date: :</h6></div>
              <div class="col-3">{{budpath}}</div> -->
          </div>

        </fieldset>
        <br>





      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ----------------------------------------------------------Un Used Code end ------------------------------------ -->


<!--------------------work flow approval S-------------------------->

<div class="modal" id="myModal2">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Approval</h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">

        <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
          <div class="col-4 text-right">
            <h6>Select Forwarded To :</h6>
          </div>
          <div class="col-4">
            <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
              [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true"
              [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
            </ng-select>
          </div>


        </div>
        <br>
        <div class="row">
          <div class="col-4 text-right">
            <h6>Remark :</h6>
          </div>
          <div class="col-4">
            <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
              placeholder="Enter Your Remark" class="form-control"></textarea>

          </div>


        </div>
        <br>

        <div>
          <table class="table table-bordered"
            style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
            <thead>
              <tr style="background-color:  white;">
                <th style="width: 20%;">S No.</th>
                <th style="width: 20%;">Decription</th>



                <th style="width: 60%;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ln of undertakings;let i = index">
                <td>{{i+1}}</td>
                <td>{{ln['desc']}}</td>
                <td style="width: 60%;">
                  <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                  <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                      style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                              <button class="btn btn-danger" (click)="copy(ln)">
                                   <i class="fa fa-times"
                                          style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                      </button> -->
                  <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                  <!-- <i class="fas fa-paste"
                                          style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                  <!-- </button>  -->
                </td>
              </tr>

            </tbody>

          </table>
        </div>
        <br>
        <div class="row" style="border: black;">
          <div class="col-4 text-right">
            <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
          </div>
          <div class="col-4">
            Notify Them
          </div>
        </div>
        <br>
        <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
          <div class="col-12 text-center">
            <button class="btn btn-primary" data-bs-dismiss="modal" (click)="Approvedapproval()">Submit

            </button>
          </div>
        </div>
        <br>
        <!-- <div *ngIf="vendorflag==true" class="row">
                  <div class="col-12 text-center">
                      <h6>OR</h6>
                  </div>
              </div> -->
        <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
          <div class="col-12 text-center">
            <button class="btn btn-primary" data-bs-dismiss="modal" (click)="Approvedapproval()">Forward To
              Vendor</button>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Status</h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">

        <div class="container">
          <a *ngFor="let ob of statusArr;let i=index">
            <div class="step completed" *ngIf="ob.status=='APPROVED'">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i < statusArr.length-1"></div>
              </div>

              <div class="content">
                {{ statusArr.length - i }} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} -
                {{ob.user_name}}
                - {{ob.status}}

              </div>
            </div>

            <div class="step" *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
              <div class="v-stepper">
                <div class="circles"></div>

              </div>

              <div class="content">
                {{ statusArr.length - i }}- {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} -
                {{ob.user_name}}
                - {{ob.status}}

                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                              {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
              </div>
            </div>

            <!-- active -->
            <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                </div>
              </div>

              <div class="content">
                {{ statusArr.length - i }} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} -
                {{ob.user_name}}
                - {{ob.status}}

                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                              {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
              </div>
            </div>

            <div class="step" *ngIf="ob.status=='PENDING'">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                </div>
              </div>

              <div class="content">
                {{ statusArr.length - i }} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} -
                {{ob.user_name}}
                - {{ob.status}}

                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                              {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
              </div>
            </div>
          </a>


        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>



          <!-- <button type="button" class="btn btn-success"data-bs-dismiss="modal" (click)="certificate()">Download
                      Certificate</button> -->


        </div>

      </div>
    </div>
  </div>
</div>
<!--------------------work flow approval S-------------------------->

<!--------------------Modal for estimat type S-------------------------->
<div class="modal" id="estimatemode">
  <div class="modal-dialog modal-lg  ">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          Estimate
        </h4>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-1"></div>
          <div class="col-4">
            <!-- <button type="button" class="btn btn-outline-primary"
                          (click)="generalEst( )">General Estimate</button>-->
          </div>
          <div class="col-2"></div>
          <div class="col-4 text-right">
            <!-- <button type="button" class="btn btn-outline-primary"
                          (click)="bridgeEst( )">Bridge Estimate</button> -->
          </div>
          <div class="col-1"></div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <!-- <button class="btn btn-primary"
                          (click)="saveappr()">{{mainService.allLableShowObj[mainService.language_cd+'BOQ54']}}
                      </button> -->
          </div>
        </div>
        <br>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger"
            data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'BOQ66']}}
            <!-- Close -->
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
<!--------------------Modal for estimat type E-------------------------->
<div class="modal fade right" id="Z" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
  aria-hidden="true">
  <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <!-- See Workflow Details -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB18"]
          }}
        </h5> <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span> </button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered"
          style=" width: 90%; margin-left: 5%; overflow-x: scroll; scroll-margin-block: 1; ">
          <thead>
            <tr style="background-color: #c4e1ff; color: white">
              <th style="width: 10%">
                <!-- S No -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB20" ]
                }}..
              </th>
              <th style="width: 20%">Levels</th>
              <th style="width: 10%">Branch</th>
              <th style="width: 20%">Branch Logical Condition</th>
              <th style="width: 10%">
                <!-- Role -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB38" ] }}
              </th>
              <th style="width: 15%">
                <!-- User -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB39" ] }}
              </th>
              <th>Role Function</th>
              <th>Vendor Approval Required</th>
              <th>Condition</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ln of dtlworkflow; let i = index">
              <td>{{ i + 1 }}</td>
              <td style="width: 20%">Level-{{ ln["level"] }}</td>
              <td style="width: 10%">Branch-{{ ln["branch"] }}</td>
              <td style="width: 20%"> {{ ln["branch_logical_condition"] }} </td>
              <td style="width: 10%"> {{ln["role_cd"]}} </td>
              <td style="width: 10%"> {{ ln["users"] }} </td>
              <td style="width: 10%"> {{ ln["role_function"] }} </td>
              <td style="width: 10%"> {{ idtovalue[ln["sent_to_vendor"]] }} </td>
              <td style="width: 10%"> {{ ln["data_desc"] }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
        </button> </div>
    </div>
  </div>
</div>



<!-- est modal-->
<div class="modal fade right" id="estModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
  aria-hidden="true">
  <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Estimates
        </h5> <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span> </button>
      </div>
      <div class="modal-body">
        <!-- Tessss -->
        <table mat-table [dataSource]="est_list_dataSource2" class="mat-elevation-z8" multiTemplateDataRows>

          <ng-container matColumnDef="est_id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element"> {{element['est_id_p']}} </td>
          </ng-container>


          <ng-container matColumnDef="work_name">
            <th mat-header-cell *matHeaderCellDef> Work Name </th>
            <td mat-cell *matCellDef="let element">
              {{work_list_obj[element.inc_id_f]['pro_name']}} </td>
          </ng-container>

          <ng-container matColumnDef="est_desc">
            <th mat-header-cell *matHeaderCellDef> Estimate Description </th>
            <td mat-cell *matCellDef="let element"> {{element.est_desc}} </td>
          </ng-container>


          <ng-container matColumnDef="fund_to_use">
            <th mat-header-cell *matHeaderCellDef> Fund To Use </th>
            <td mat-cell *matCellDef="let element"> {{element.fund_use}} </td>
          </ng-container>

          <ng-container matColumnDef="est_date">
            <th mat-header-cell *matHeaderCellDef> Estimate Date </th>
            <td mat-cell *matCellDef="let element"> {{element.est_date}} </td>
          </ng-container>

          <ng-container matColumnDef="cal_est">
            <th mat-header-cell *matHeaderCellDef> Calculation Estimate (Rs.)</th>
            <td mat-cell *matCellDef="let element"> {{element.finalAmt}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>

          <ng-container matColumnDef="mode">
            <th mat-header-cell *matHeaderCellDef> Mode </th>
            <td mat-cell *matCellDef="let element"> {{element.est_type}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button class=" btn btn-outline-primary" (click)="Appr(element)"
                *ngIf="element['status'] == 'GENERATED' && apprflag == true"> Add Approval
              </button>
              <button class=" btn btn-outline-primary" (click)="viewStatus(element)"
                *ngIf="element['status'] != 'GENERATED'"> View Status </button>
              <button class=" btn btn-outline-danger" (click)="deleteest(element)"
                *ngIf="element['status'] == 'GENERATED'"> Delete </button>
              <button class="btn btn-outline-primary" (click)="open_update(element)"
                *ngIf="element['status'] == 'GENERATED'"> Update </button>
              <button class="btn btn-outline-primary" (click)="view_dtl(element)">View Details
              </button>



              <button class="btn btn-success" (click)="seeworkflow(element)">
                {{ mainService.allLableShowObj[
                mainService.language_cd + "EMB-EMB18" ] }}
              </button>

              <button class="btn btn-outline-warning" (click)="download_inputs(element)">Download Inputs
              </button>
              <button class="btn btn-outline-secondary" (click)="downlod_meas(element)">Download Details Of
                Measurement
              </button>
              <button class="btn btn-outline-success" (click)="download_ana(element)">Download Analysis Of Rate
              </button>
              <button class="btn btn-outline-warning" (click)="download_boq(element)">Download Boq
              </button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="estimate_list_columns"></tr>
          <tr mat-row *matRowDef="let row; columns: estimate_list_columns;"></tr>
        </table>
      </div>
      <div class="modal-footer"> <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
        </button> </div>
    </div>
  </div>
</div>
